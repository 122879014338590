import Resizer from 'react-image-file-resizer'
import _ from 'lodash'
import nodeDefinitionTypeMap from '../../src/components/scoring_tree/helper/nodeDefinitionTypeMap'
import mediaCollectionsMap from './mediaCollectionsMap'

const ObjectID = require('bson-objectid')

let t0 = false
let t1 = false
let t2 = false
let debug = false

export function getComparisonElementWidth(viewPort, itemsCount) {
  const leftGap = 224
  const scrollbarSize = 20
  const availableSize = viewPort - leftGap - scrollbarSize

  const maxWidth = 220
  const itemSpaceWithMargins = availableSize / itemsCount

  const maxMarginBetweenItems = 20
  const minMarginBetweenItems = 10

  const marginBetweenItems =
    itemSpaceWithMargins - maxMarginBetweenItems < maxWidth ? minMarginBetweenItems : maxMarginBetweenItems

  const itemWidthWithoutMargins = itemSpaceWithMargins - marginBetweenItems
  const itemWidth = itemWidthWithoutMargins > maxWidth ? maxWidth : itemWidthWithoutMargins

  return {
    marginBetweenItems,
    itemWidth,
  }
}

export function getComparisonPopupElementWidth(viewPort, itemsCount) {
  const leftGap = 174
  const rightGap = 78
  const scrollbarSize = 20
  const availableSize = viewPort - leftGap - scrollbarSize - rightGap

  const itemSpaceWithMargins = availableSize / itemsCount
  const marginBetweenItems = 10

  const itemWidth = itemSpaceWithMargins - marginBetweenItems

  const thumbExpectedWidth = 80
  const fittingThumbs = Math.floor(itemWidth / thumbExpectedWidth)
  const thumbWidth = itemWidth / fittingThumbs

  return {
    marginBetweenItems,
    itemWidth,
    fittingThumbs,
    thumbWidth,
  }
}

export function setScoreButtonColorStyle(bonusDemerit, score, isSelected) {
  isSelected = !isSelected ? false : isSelected
  bonusDemerit = !bonusDemerit ? false : bonusDemerit
  score = parseFloat(score)
  let style = {}
  if (bonusDemerit) {
    style = {
      criterion_score_btn_unscored: true,
      hover_demerit_minus_2: score === -2,
      hover_demerit_minus_1: score === -1,
      hover_demerit_0: score === 0,
      hover_demerit_plus_1: score === 1,
      hover_demerit_plus_2: score === 2,
    }
  }
  if (!bonusDemerit) {
    style = {
      criterion_score_btn_unscored: true,
      hover_0: score === 0,
      hover_0_5: score === 0.5,
      hover_1: score === 1,
      hover_1_5: score === 1.5,
      hover_2: score === 2,
      hover_2_5: score === 2.5,
      hover_3: score === 3,
      hover_3_5: score === 3.5,
      hover_4: score === 4,
      hover_4_5: score === 4.5,
      hover_5: score === 5,
    }
  }
  if (!isSelected) {
    return style
  }
  if (bonusDemerit) {
    style = {
      criterion_score_demerit_minus_2: score === -2,
      criterion_score_demerit_minus_1: score === -1,
      criterion_score_demerit_0: score === 0,
      criterion_score_demerit_plus_1: score === 1,
      criterion_score_demerit_plus_2: score === 2,
      ...style,
    }
  }
  if (!bonusDemerit) {
    style = {
      criterion_score_value_0: score === 0,
      criterion_score_value_0_5: score === 0.5,
      criterion_score_value_1: score === 1,
      criterion_score_value_1_5: score === 1.5,
      criterion_score_value_2: score === 2,
      criterion_score_value_2_5: score === 2.5,
      criterion_score_value_3: score === 3,
      criterion_score_value_3_5: score === 3.5,
      criterion_score_value_4: score === 4,
      criterion_score_value_4_5: score === 4.5,
      criterion_score_value_5: score === 5,
      ...style,
    }
  }
  return style
}

export function shortenScorePanelRowName(name) {
  switch (name) {
    case 'Exterior':
      return 'EXT'
    case 'Interior':
      return 'INT'
    case 'Cargo Interior':
      return 'CARGO'
    case 'Digital':
      return 'DIGIT'
    default:
      return name
  }
}

export function shortenScorePanelColName(name) {
  switch (name) {
    case 'INITIAL IMPRESSION':
      return 'INITIAL'
    case 'DETAILED ANALYSIS':
      return 'DETAILED'
    default:
      return name
  }
}

export function getProductBrandAndModelLabel(props) {
  if (!props) {
    return ''
  }

  const brandProp = props.find(item => {
    if (item.slug !== undefined && item.slug === 'brand') {
      return true
    }

    return false
  })

  const modelProp = props.find(item => {
    if (item.slug !== undefined && item.slug === 'model') {
      return true
    }

    return false
  })

  let label = brandProp && brandProp.value && brandProp.value.body && brandProp.value.body.en
  label += ' '
  label += modelProp && modelProp.value && modelProp.value.body && modelProp.value.body.en

  return label
}

export function createInterRankingProductsList(productsFromServer, filteredScore, pin) {
  const productsList = []
  if (productsFromServer) {
    if (productsFromServer.length > 0) {
      for (let i = 0; i < productsFromServer.length; i += 1) {
        const product = productsFromServer[i]
        let b = true
        b = filteredScore && product.score !== filteredScore ? false : b
        b = pin && product.id === pin.id ? false : b
        if (b) {
          productsList.push(product)
        }
      }
    }
  }
  return productsList
}

export function interRankingGetFirstIndexWithThisScoringId(productsFromServer, filteredScore, pin, scoringId) {
  const productsList = createInterRankingProductsList(productsFromServer, filteredScore, pin)
  for (let i = 0; i < productsList.length; i += 1) {
    if (productsList[i].id === scoringId) {
      return i
    }
  }
  return false
}

export function interRankingGetFirstIndexWithThisScore(productsFromServer, filteredScore, pin, score) {
  const productsList = createInterRankingProductsList(productsFromServer, filteredScore, pin)
  for (let i = 0; i < productsList.length; i += 1) {
    if (productsList[i].score === score) {
      return i
    }
  }
  return false
}

export function createBreadcrumb(nodeDefsObj, currentNodeId, lang) {
  const breadcrumb = []
  if (
    !currentNodeId ||
    currentNodeId === '' ||
    !nodeDefsObj ||
    Object.keys(nodeDefsObj).length === 0 ||
    !lang ||
    lang === ''
  ) {
    return breadcrumb
  }
  while (currentNodeId !== null) {
    const currentNode = nodeDefsObj[currentNodeId]
    const nodeName = currentNode.name[lang]
    breadcrumb.unshift(nodeName)
    currentNodeId = currentNode.parent_id
  }
  return breadcrumb
}

export function secureAdd(myValue, myNumber) {
  myValue = myValue === undefined ? 0 : parseInt(myValue, 10)
  myValue += parseInt(myNumber, 10)
  return myValue
}

export function sleep(milliseconds) {
  const date = Date.now()
  let currentDate = null
  do {
    currentDate = Date.now()
  } while (currentDate - date < milliseconds)
}

export function pad(sInit, size) {
  const s = '0'.repeat(size) + sInit
  return s.substr(-1 * size)
}

export function isEmptyObject(obj) {
  return Object.entries(obj).length === 0
}

export function jsDateToDatetime(now, noSpaces) {
  const month = pad(now.getMonth() + 1, 2)
  const day = pad(now.getDate(), 2)
  const hours = pad(now.getHours())
  const minutes = pad(now.getMinutes())
  const seconds = pad(now.getSeconds())
  let ret = ''
  if (!noSpaces) {
    ret = `${now.getFullYear()}-${month}-${day} ${hours}:${minutes}:${seconds}`
  }
  if (noSpaces) {
    ret = `${now.getFullYear()}${month}${day}${hours}${minutes}${seconds}`
  }
  return ret
}

export function chunkStr(str, chunkLength) {
  return str.match(new RegExp(`[\\s\\S]{1,${+chunkLength}}`, 'g'))
}

export function splitTextToLines(str, chunkLength) {
  const idealSplit = 7
  let lineCounter = 0
  let lineIndex = 0
  const lines = ['']
  let ch
  let i

  if (str) {
    for (i = 0; i < str.length; i += 1) {
      ch = str[i]
      if ((lineCounter >= idealSplit && ch === ' ') || lineCounter >= chunkLength) {
        ch = ''
        lineCounter = -1
        lineIndex += 1
        lines.push('')
      }
      lines[lineIndex] += ch
      lineCounter += 1
    }
  }

  return lines
}

export function generateOfflineScoringObj(scoring, template, offlinePictures) {
  const offlineScoringObj = {}
  offlineScoringObj.id = scoring.id
  offlineScoringObj._source = {}
  offlineScoringObj._source.id = scoring.id
  for (let i = 0; i !== offlinePictures.length; i += 1) {
    const picture = offlinePictures[i]
    if (picture.collection === mediaCollectionsMap.cover_pictures) {
      offlineScoringObj._source.cover = picture
    } else if (picture.collection === mediaCollectionsMap.full_exterior) {
      offlineScoringObj._source.full_exterior = picture
    } else if (picture.collection === mediaCollectionsMap.full_interior) {
      offlineScoringObj._source.full_interior = picture
    }
  }

  offlineScoringObj._source.status = scoring.status
  offlineScoringObj._source.created_at = scoring.created_at
  offlineScoringObj._source.updated_at = scoring.updated_at
  offlineScoringObj._source.max_score = scoring.max_score
  offlineScoringObj._source.global_score = scoring.global_score
  offlineScoringObj._source.author = scoring.author
  offlineScoringObj._source.props = {}
  offlineScoringObj._source.score_panel = scoring.score_panel
  offlineScoringObj._source.template = {}
  offlineScoringObj._source.template.id = template.id
  offlineScoringObj._source.template.name = template.name.en
  offlineScoringObj._source.project_mode = scoring.project_mode

  // Get bran media from template
  const propBrand = scoring.props.find(item => item.slug === 'brand')
  const templateBrand = template.scope.props.find(item => item.slug === 'brand')
  const singleTemplateBrand = templateBrand.values.find(item => item.slug === propBrand.value.slug)

  scoring.props.map(item => {
    if (item.slug === 'brand' || item.slug === 'model' || item.slug === 'main-segment') {
      const value = item.value.body.en
      item.value = value
      if (
        item.slug === 'brand' &&
        singleTemplateBrand &&
        singleTemplateBrand.media &&
        singleTemplateBrand.media.length > 0
      ) {
        item.media = singleTemplateBrand.media[0].url
      }
      offlineScoringObj._source.props[item.slug] = item
    }
    return item
  })

  return offlineScoringObj
}

export function caluclateCompletedPercentage(defKeys, nodeDefsObj, nodes) {
  let criteriaScored = 0
  let criteriaTotal = 0
  defKeys.map(key => {
    if (nodeDefsObj[key].type === nodeDefinitionTypeMap.criterion && nodes[key].is_enabled === true) {
      criteriaScored += nodes[key].is_default === false ? 1 : 0
      criteriaTotal += 1
    }
    return key
  })
  const completedPercentage =
    criteriaScored > 0 && criteriaTotal > 0 ? Math.round((criteriaScored * 100) / criteriaTotal) : 0
  return completedPercentage
}

export function showPercentage(n, d) {
  if (d === undefined || d === '' || d === 0) {
    return ''
  }
  return `${Math.round((n / d) * 100)}%`
}

export function toFixed1IfDecimal(num) {
  let result = num
  if (num && num !== '' && Math.round(num) !== num) {
    result =
      JSON.parse(global.env.normalizedScoreDecimals) === 1 ? parseFloat(num).toFixed(1) : (result = Math.round(num))
    const v = result.toString().split('.')
    if (v[1] === '0') {
      result = Math.round(num)
    }
  }
  return result
}

export function decreaseToZero(obj, field) {
  obj[field] = obj[field] <= 0 ? 0 : (obj[field] -= 1)
}

export function isoDate() {
  const now = new Date()
  return new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString()
}

export function mongoObjectId() {
  const id = new ObjectID()
  return id.toHexString()
}

export function serverRespondsOk(status) {
  return status === 200 || status === 201
}

export function imageResize(file, width, height, quality = 90, outputFormat = 'base64') {
  return new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      width,
      height,
      'JPEG',
      quality,
      0,
      result => {
        resolve(result)
      },
      outputFormat
    )
  })
}

export function readUploadedFileAsBase64(inputFile, fileExtension) {
  fileExtension =
    fileExtension ||
    inputFile?.name
      .split('.')
      .pop()
      .toLowerCase()

  const temporaryFileReader = new FileReader()

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort()
      reject(new DOMException('Problem parsing input file.'))
    }
    temporaryFileReader.readAsDataURL(inputFile)
    temporaryFileReader.onload = e => {
      const base64 = e.target.result
      const img = new Image()
      let imgObj = {}
      imgObj.base64 = base64

      let compressedBase64 = null
      const clientCompressionEnabled =
        process.env.clientSideImageCompressionEnabled &&
        JSON.parse(process.env.clientSideImageCompressionEnabled) === true

      switch (fileExtension.toLowerCase()) {
        case 'qt':
        case 'mov':
        case 'mp4':
          imgObj.originalImgWidth = 0
          imgObj.originalImgHeight = 0
          resolve(imgObj)
          break
        default:
          img.src = base64

          img.onload = async function() {
            if (clientCompressionEnabled) {
              compressedBase64 = await imageResize(
                inputFile,
                process.env.clientSideImageCompressionMaxWidth,
                process.env.clientSideImageCompressionMaxHeight,
                process.env.clientSideImageCompressionQuality || 90
              )
            }
            imgObj = {}
            imgObj.base64 = clientCompressionEnabled ? compressedBase64 : base64
            imgObj.originalImgWidth = img.width
            imgObj.originalImgHeight = img.height
            resolve(imgObj)
          }
          break
      }
    }
  })
}

export function urlToBlob(url) {
  const BASE64_MARKER = ';base64,'

  if (url.indexOf(BASE64_MARKER) === -1) {
    const parts = url.split(',')
    const contentType = parts[0].split(':')[1]
    const raw = parts[1]

    return new Blob([raw], { type: contentType })
  }

  const parts = url.split(BASE64_MARKER)
  const contentType = parts[0].split(':')[1]
  const raw = atob(parts[1])
  const rawLength = raw.length

  const uInt8Array = new Uint8Array(rawLength)

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i)
  }

  return new Blob([uInt8Array], { type: contentType })
}

export function isFileImage(file) {
  const acceptedImageTypes = ['image/jpeg', 'image/png']

  return file && acceptedImageTypes.includes(file.type)
}

export function readUploadedAttachmentAsBase64(inputFile) {
  const temporaryFileReader = new FileReader()

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort()
      reject(new DOMException('Problem parsing input file.'))
    }
    temporaryFileReader.readAsDataURL(inputFile)
    temporaryFileReader.onload = e => {
      const file = e.target.result
      const imgObj = {}
      imgObj.file = file
      resolve(imgObj)
    }
  })
}

export function logTimed(label) {
  switch (label) {
    case 'debug_off':
      debug = false
      break
    case 'debug_on':
      debug = true
      break
    case 'start':
      t0 = new Date()
      t1 = new Date()
      t2 = new Date()
      break
    case 'stop':
      t1 = t0
      t2 = new Date()
      break
    default:
      t1 = t2
      t2 = new Date()
      break
  }
  if (debug && label !== 'debug_on') {
    const ms = t2 - t1
    console.log(`TIMER - ${label}: `, `${ms} ms`)
  }
}

export function nowJsDate() {
  return new Date()
}

export function array2ObjectGroupedByField(list, groupField) {
  const unique = [...new Set(list.map(item => item[groupField]))]
  const returnObj = {}
  for (let i = 0; i !== unique.length; i += 1) {
    returnObj[unique[i]] = []
  }

  for (let i = 0; i !== list.length; i += 1) {
    const item = list[i]
    returnObj[item[groupField]].push(item)
  }

  return returnObj
}

export function array2Object(list, idField) {
  const returnObj = {}
  for (let i = 0; i !== list.length; i += 1) {
    const item = list[i]
    returnObj[item[idField]] = item
  }
  return returnObj
}

export function object2Array(object) {
  const returnArray = []
  Object.keys(object).forEach(nodeDefinitionId => {
    returnArray.push(object[nodeDefinitionId])
  })
  return returnArray
}

export async function loadDependency(dependencyName) {
  const dependency = await import(`../specializations/${global.env.client}/${dependencyName}`)
  return dependency
}

export function getTextWidth(text, fontSize) {
  const defaultFont =
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif"
  const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'))
  const context = canvas.getContext('2d')
  context.font = `${fontSize} ${defaultFont}`
  const metrics = context.measureText(text)
  return metrics.width
}

export function guessScoringsUploadTime(scorings, scoringsKeys) {
  let sec = 0
  if (scorings) {
    for (let i = 0; i < scoringsKeys.length; i += 1) {
      const scoringKey = scoringsKeys[i]
      const nodesScoringKeys = Object.keys(scorings[scoringKey].nodes)
      sec += scoringsKeys.synced === false ? 15 : nodesScoringKeys.length * 0.01
    }
  }
  return sec
}

export function updateQuerystringWithoutReloadPage(key, value) {
  if (typeof window !== 'undefined' && window.history.pushState) {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set(key, value)
    const newurl = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?${searchParams.toString()}`
    window.history.pushState({ path: newurl }, '', newurl)
  }
}

export function getTotalBonusDemeritScoreRecursively(nodeDefinitions, nodes, node) {
  let score = 0
  const childrenIds = nodeDefinitions[node.node_definition_id].children_ids
  if (childrenIds.length > 0) {
    childrenIds.forEach(id => {
      const child = nodes[id]
      if (nodeDefinitions[id].children_ids.length === 0 && nodeDefinitions[id].bonus_demerit === true) {
        // This is the last level AND it is a bonus demerit node
        score += parseFloat(child.score)
      } else if (nodeDefinitions[id].children_ids.length > 0) {
        score += getTotalBonusDemeritScoreRecursively(nodeDefinitions, nodes, child)
      }
    })
  }

  return score
}

export function countChildrenCriteria(object, collection) {
  const leafs = _.filter(collection, item => {
    if (item._left > object._left && item._right < object._right && item._right === item._left + 1) {
      return true
    }

    return false
  })

  return leafs.length
}

export function isValidURL(string) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  return !!pattern.test(string)
}
