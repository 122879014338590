import { tusUpload } from '../../api/media'
import storage from '../../storage'
import storageMap from '../../storage/storageMap'
import { SYNC_ACTION_UPLOAD_MEDIA } from '../consts'
import DefaultActionHandler from './defaultActionHandler'

class UploadMediaHandler extends DefaultActionHandler {
  constructor() {
    super()
    this.setAction(SYNC_ACTION_UPLOAD_MEDIA)
  }

  add = async data => {
    const { id } = data

    await storage.removeByFilter(
      storageMap.sync_queue_v2,
      item => item.action === SYNC_ACTION_UPLOAD_MEDIA && item.data.id === id
    )

    await super.add(data)
  }

  process = async item => {
    const { data } = item
    const { file, ...metadata } = data

    return new Promise((resolve, reject) => {
      tusUpload(file, metadata)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

export default UploadMediaHandler
