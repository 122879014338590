import storage from '../../storage'
import storageMap from '../../storage/storageMap'
import { SYNC_ACTION_CREATE_PRODUCT, SYNC_ACTION_DELETE_PRODUCT, SYNC_ACTION_UPDATE_PRODUCT } from '../consts'
import DefaultActionHandler from './defaultActionHandler'

class DeleteProductHandler extends DefaultActionHandler {
  constructor() {
    super()
    this.setAction(SYNC_ACTION_DELETE_PRODUCT)
  }

  add = async data => {
    const { id } = data

    const itemsRemovedCount = storage.removeByFilter(
      storageMap.sync_queue_v2,
      item =>
        (item.action === SYNC_ACTION_CREATE_PRODUCT || item.action === SYNC_ACTION_UPDATE_PRODUCT) &&
        item.data.id === id
    )

    if (itemsRemovedCount <= 0) {
      await super.add(data)
    }
  }

  process = async item => {
    // TODO
  }
}

export default DeleteProductHandler
