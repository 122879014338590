import { calculate } from '../specializations'
import { array2Object } from '.'
import normalizeDirectionMap from './normalizeDirectionMap'

export function normalizeMedia(media, scoring, template, direction) {
  let node = {}
  switch (direction) {
    case normalizeDirectionMap.to_server:
    case 'to_server':
      delete media.items_father_id
      break
    case normalizeDirectionMap.from_server:
    case 'from_server':
      media.scoring_id = scoring.id
      media.file_type = media.mime_type
      delete media.mime_type

      // Guess father_items_id from template
      if (typeof media.node_definition_id !== 'undefined' && media.node_definition_id !== null) {
        node = template.node_definitions[media.node_definition_id]
        node = template.node_definitions[node.parent_id]
        if (node.type === 'item') {
          node = template.node_definitions[node.parent_id]
        }
        media.items_father_id = node.id
      }

      break
    default:
      break
  }
}

export function normalizeNode(node, direction) {
  switch (direction) {
    case normalizeDirectionMap.to_server:
      node.max_score = node.maxScore
      delete node.maxScore
      node.normalized_score = node.normalizedScore
      delete node.normalizedScore

      if (node.forecast) {
        node.forecast.normalized_score = node.forecast.normalizedScore
        delete node.forecast.normalizedScore
      }

      break
    case normalizeDirectionMap.from_server:
      if (node.forecast) {
        if (typeof node.forecast.normalized_score !== 'undefined') {
          node.forecast.normalizedScore = node.forecast.normalized_score
          delete node.forecast.normalized_score
        }
      }

      break
    default:
      break
  }

  return node
}

export function normalizeComment(comment, scoring, template, direction) {
  let node = {}
  switch (direction) {
    case normalizeDirectionMap.to_server:
      delete comment.items_father_id
      comment.body = comment.text
      delete comment.text
      comment.locale_id = comment.locale.id
      delete comment.locale
      comment.author_id = comment.author.id
      delete comment.author
      break
    case normalizeDirectionMap.from_server:
      // Guess father_items_id from template
      node = template.node_definitions[comment.node_definition_id]
      node = template.node_definitions[node.parent_id]
      if (node.type === 'item') {
        node = template.node_definitions[node.parent_id]
      }

      comment.items_father_id = node.id
      comment.text = comment.body
      delete comment.body
      comment.scoring_id = scoring.id
      break
    default:
      break
  }

  return comment
}

export async function normalizeScoring(scoring, template, user, direction) {
  // VAV3-587: not needed for the reason below
  // const { nodes } = scoring
  // const defs = template.node_definitions
  let nodeDefKeys = []
  switch (direction) {
    case 'to_server':
    case normalizeDirectionMap.to_server:
      scoring.author_id = scoring.author !== undefined && scoring.author.id !== undefined ? scoring.author.id : user.id
      break
    case 'from_server':
    case normalizeDirectionMap.from_server: {
      scoring.nodes = array2Object(scoring.nodes, 'node_definition_id')
      const duplicatedNodes = { ...scoring.nodes }
      await calculate(scoring, template.node_definitions, duplicatedNodes)
      nodeDefKeys = Object.keys(duplicatedNodes)
      for (let i = 0; i < nodeDefKeys.length; i += 1) {
        duplicatedNodes[nodeDefKeys[i]].isExpanded = i < 3
        // it will be better to normalize before the calc
        duplicatedNodes[nodeDefKeys[i]] = normalizeNode(scoring.nodes[nodeDefKeys[i]], normalizeDirectionMap[direction])
      }
      scoring.nodes = duplicatedNodes
      break
    }
    default:
      break
  }

  return scoring
}
