import React from 'react'
import { connect } from 'react-redux'

import './styles.scss'
import { Switch } from '@material-ui/core'
import _ from 'lodash'
import * as actionCreators from '../../store/actions'

import ScoringSearchBar from './_parts/search/scoringSearchBar'
import ScoringSearchHits from './_parts/search/scoringSearchHits'
import ScoringSearchPagination from './_parts/search/scoringSearchPagination'
import ScoringSpecifyProduct from './_parts/scoringSpecifyProduct'
import screenTypesMap from '../../layout/_parts/screenTypesMap'

import ChevronIcon from '../../assets/svg/button-chevron.svg'
import ChevronBackIconSec from '../../assets/svg/button-desk-chevron-back-sec.svg'
import scoringStepsMap from './_parts/helper/scoringStepsMap'

import CheckWhite from '../../assets/svg/check-white.svg'
import { LoadingBar } from '../loading_bar'
import { getScoringMediaById } from '../../../va-corejs-v3/actions/scoring_tree'
import mediaCollectionsMap from '../../../va-corejs-v3/utils/mediaCollectionsMap'
import scoringPropsTypeMap from './_parts/helper/scoringPropsTypeMap'
import { isProjectModeEnabled } from '../scoring_tree/_parts/projectMode/utils'
import syncQueue from '../../../va-corejs-v3/sync/syncQueue'
import { SYNC_ACTION_CREATE_PRODUCT } from '../../../va-corejs-v3/sync/consts'
import scopePropsMap from './_parts/helper/scopePropsMap'

const classNames = require('classnames')

export class CreateScoringComponent extends React.Component {
  constructor(props) {
    super(props)

    this.creationStatusMap = {
      formEditing: 0,
      requestSent: 1,
      completed: 2,
    }

    this.state = {
      processState: this.creationStatusMap.formEditing,
      showLoadingBar: false,
      // projectModeEnable: false,
    }
  }

  handleCreateScoringClicked = async () => {
    const { scoring, updateOfflineScoringList } = this.props
    this.setState({ processState: this.creationStatusMap.requestSent })

    await syncQueue.add(SYNC_ACTION_CREATE_PRODUCT, { id: scoring.newScoringId })

    await updateOfflineScoringList(scoring)

    if (typeof window !== 'undefined') {
      window.location.href = `/scoring?id=${scoring.newScoringId}`
    }
  }

  componentDidMount = async () => {
    const { scoring, updateScoringSearch } = this.props
    updateScoringSearch('', scoring.scoringSearch.pageSize, scoring.scoringSearch.currentPage)
  }

  componentWillReceiveProps = async nextProps => {
    const { scoring, environment } = nextProps
    let createEnabled = false
    let allFieldsValidated = false

    const featuredImage = await getScoringMediaById(scoring.newScoringId, mediaCollectionsMap.cover_pictures)

    if (featuredImage.length > 0) {
      for (let i = 0; i !== scoring.mandatoryProps.length; i += 1) {
        const propSlug = scoring.mandatoryProps[i]
        const propFilteredList = scoring.scoringProps.filter(x => x.slug === propSlug)
        let hasValue = false

        if (propFilteredList.length > 0) {
          const propObj = propFilteredList[0]
          if (propObj.type === scoringPropsTypeMap.datalist) {
            hasValue = propObj.value.body[environment.defaultLang].length > 0
          } else if (propObj.type === scoringPropsTypeMap.text) {
            hasValue = propObj.value.body[environment.defaultLang].length > 0
          } else if (propObj.type === scoringPropsTypeMap.distance) {
            hasValue = propObj.props[0].value.body[environment.defaultLang].length > 0
          } else if (propObj.type === scoringPropsTypeMap.price) {
            const priceOfModelProp = _.find(propObj.props, _prop => _prop.slug === scopePropsMap.price_of_model)
            const priceMinProp = _.find(propObj.props, _prop => _prop.slug === scopePropsMap.price_minimum)
            const priceMaxProp = _.find(propObj.props, _prop => _prop.slug === scopePropsMap.price_maximum)

            hasValue =
              (priceMinProp.value.body[environment.defaultLang].length > 0 &&
                priceMaxProp.value.body[environment.defaultLang].length > 0) ||
              priceOfModelProp.value.body[environment.defaultLang].length > 0
          } else if (propObj.type === scoringPropsTypeMap.text_or_image) {
            hasValue =
              propObj.props[0].value.body[environment.defaultLang].length > 0 ||
              propObj.props[1].value.body[environment.defaultLang].length > 0
          }
        }

        allFieldsValidated = hasValue

        if (!hasValue) {
          break
        }
      }
    }
    createEnabled = allFieldsValidated
    this.setState({ createEnabled })
  }

  enableProjectMode = e => {
    const { scoring, setProjectMode } = this.props
    setProjectMode(scoring.newScoringId, e.target.checked)
  }

  render() {
    const {
      texts,
      scoring,
      deleteTempScoringFromStorageAndResetPanel,
      setScoringCreationStep,
      updateScrollDisabledStatus,
      device,
    } = this.props
    const { processState, createEnabled, showLoadingBar } = this.state

    function handleCancelButtonClicked() {
      updateScrollDisabledStatus(false)
      deleteTempScoringFromStorageAndResetPanel(scoring.newScoringId)
    }

    function handleNextStepClicked() {
      setScoringCreationStep(scoringStepsMap.specifyProduct)
    }
    function handleChangeTemplateClicked() {
      setScoringCreationStep(scoringStepsMap.chooseTemplate)
    }

    return (
      <div>
        <div className="popup_background_layer" />
        <div className="popup_outer_container">
          <div className="popup_inner_container">
            <div>
              <div>
                <div className="popup_head">
                  <span className="popup_title">{texts.new_scoring}</span>
                  <span className="ns_step_text">
                    {scoring.scoringCreationStep === scoringStepsMap.chooseTemplate
                      ? texts.new_scoring_step_1
                      : texts.new_scoring_step_2}
                  </span>
                  <span
                    role="button"
                    className={classNames({
                      popup_cancel_btn: true,
                      theme_color_hover: true,
                    })}
                    onClick={handleCancelButtonClicked}
                    onKeyPress={handleCancelButtonClicked}
                    tabIndex={0}
                  >
                    {texts.cancel}
                  </span>
                  {scoring.scoringCreationStep === scoringStepsMap.chooseTemplate && (
                    <div>
                      {isProjectModeEnabled() && (
                        <div className="scoring_enable_project_mode">
                          <span className="scoring_project_mode_label">{texts.project_mode}</span>
                          <Switch
                            disableRipple
                            className="va_small_switch"
                            checked={scoring.project_mode}
                            onChange={e => this.enableProjectMode(e)}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        </div>
                      )}
                      <ScoringSearchBar />
                    </div>
                  )}
                </div>

                <div
                  className={classNames({
                    popup_body_container: true,
                    project_mode_enabled: isProjectModeEnabled(),
                    ns_body_step_1: scoring.scoringCreationStep === scoringStepsMap.chooseTemplate,
                    ns_body_step_2: scoring.scoringCreationStep === scoringStepsMap.specifyProduct,
                    isSafari: device.isSafari,
                    isFirefox: device.isFirefox,
                    desk: device.screenType === screenTypesMap.desk,
                    ipadh: device.screenType === screenTypesMap.iPadH,
                    ipadv: device.screenType === screenTypesMap.iPadV,
                    iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                    iphonese: device.screenType === screenTypesMap.iPhoneSE,
                  })}
                >
                  {scoring.scoringCreationStep === scoringStepsMap.chooseTemplate && (
                    <div>
                      <ScoringSearchHits />
                      <ScoringSearchPagination />
                    </div>
                  )}
                  {scoring.scoringCreationStep === scoringStepsMap.specifyProduct && <ScoringSpecifyProduct />}
                </div>
                <div
                  className={classNames({
                    popup_footer: true,
                    isSafari: device.isSafari,
                    isFirefox: device.isFirefox,
                    desk: device.screenType === screenTypesMap.desk,
                    ipadh: device.screenType === screenTypesMap.iPadH,
                    ipadv: device.screenType === screenTypesMap.iPadV,
                    iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                    iphonese: device.screenType === screenTypesMap.iPhoneSE,
                    theme_svg: true,
                  })}
                >
                  {scoring.scoringCreationStep === scoringStepsMap.chooseTemplate && (
                    <button
                      type="button"
                      className={classNames({
                        sm_btn: true,
                        theme_primary_btn: true,
                        float_right_btn: true,
                        btn_200: true,
                        disabled_btn: scoring.selectedTemplate === 0,
                        theme_background_color: true,
                      })}
                      onClick={scoring.selectedTemplate !== 0 ? handleNextStepClicked : null}
                    >
                      <span>{texts.next_step}</span>
                      <ChevronIcon />
                    </button>
                  )}
                  {scoring.scoringCreationStep === scoringStepsMap.specifyProduct && (
                    <div>
                      <button
                        type="button"
                        className={classNames({
                          sm_btn: true,
                          btn_200: true,
                          secondary_btn: true,
                          theme_color: true,
                        })}
                        onClick={handleChangeTemplateClicked}
                      >
                        <ChevronBackIconSec />
                        <span>{texts.change_template}</span>
                      </button>
                      <button
                        type="button"
                        className={classNames({
                          sm_btn: true,
                          theme_primary_btn: true,
                          float_right_btn: true,
                          btn_200: true,
                          disabled_btn: !createEnabled,
                        })}
                        onClick={() => {
                          if (createEnabled) {
                            this.handleCreateScoringClicked()
                          }
                        }}
                      >
                        <span
                          className={classNames({
                            btn_label_with_icon: processState !== this.creationStatusMap.formEditing,
                            btn_label_with_working_icon: processState === this.creationStatusMap.requestSent,
                            btn_label_with_check_icon: processState === this.creationStatusMap.completed,
                          })}
                        >
                          {texts.create_product}
                        </span>
                        {processState === this.creationStatusMap.requestSent && (
                          <div
                            className={classNames({
                              btn_spinner_container: true,
                            })}
                          >
                            <span className={classNames({ btn_spinner: true })} />
                          </div>
                        )}
                        {processState === this.creationStatusMap.completed && <CheckWhite />}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {processState === this.creationStatusMap.requestSent && showLoadingBar && (
          <div className={classNames({ full_width_loading_outer_container: true })}>
            <div
              className={classNames({
                full_width_loading_inner_container: true,
              })}
            >
              <LoadingBar />
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
    environment: state.environment,
    texts: state.texts.values,
    scoring: state.scoring,
  }
}

export default connect(mapStateToProps, actionCreators)(CreateScoringComponent)
