import _ from 'lodash'
import { SYNC_ACTION_UPDATE_PRODUCT } from '../consts'
import DefaultActionHandler from './defaultActionHandler'
import { createOrUpdate } from '../../api/products'
import storage from '../../storage'
import storageMap from '../../storage/storageMap'
import { normalizeScoring } from '../../utils/dataNormalizers'
import { remove as removeMedia, tusUpload } from '../../api/media'
import { setScoringIntoLocalStorage } from '../../actions/scoring'
import { urlToBlob } from '../../utils'

class UpdateProductHandler extends DefaultActionHandler {
  constructor() {
    super()
    this.setAction(SYNC_ACTION_UPDATE_PRODUCT)
  }

  async add(data) {
    const { id } = data

    const existingItems = await storage.filter(
      storageMap.sync_queue_v2,
      item => item.action === SYNC_ACTION_UPDATE_PRODUCT && item.data.id === id
    )

    if (existingItems.length <= 0) {
      // Create
      await super.add(data)
    } else {
      // Update
      const existingItem = existingItems[0]
      existingItem.added_at = Date.now()
      await storage.update(storageMap.sync_queue_v2, existingItem)
    }
  }

  process = async item => {
    const { data, id: itemId } = item
    const { id } = data

    const product = await storage.get(storageMap.scoring, id)

    // Handle product info
    await this._handleUpdateProductBatch(product, itemId)

    // Handle product media deletion
    await this._handleDeleteMediaBatches(product, itemId)

    // Handle product media uploading
    await this._handleUploadMediaBatches(product, itemId)

    // Store the new informations about the product
    await setScoringIntoLocalStorage(product)
  }

  _handleUpdateProductBatch = async (product, itemId) => {
    const batchCode = `update_product_${product.id}`

    const isBatchCompleted = await this.isBatchCompleted(batchCode, itemId)

    if (isBatchCompleted) {
      return
    }

    const users = await storage.getAll(storageMap.user)

    let newProduct = { ...product }
    newProduct = await normalizeScoring(newProduct, {}, users[0], 'to_server')

    const scoreDigital = await storage.get(storageMap.extra_data, `scoring_${product.id}_score_digital`)
    newProduct.score_digital = scoreDigital ? scoreDigital.data : ''

    delete newProduct.nodes
    delete newProduct.media
    if (newProduct.mediaToDelete) delete newProduct.mediaToDelete

    // Save the product
    await createOrUpdate(product.id, newProduct)

    await this.completeBatch(batchCode, itemId)
  }

  _handleDeleteMediaBatches = async (product, itemId) => {
    const batchPrefix = 'delete_media_'

    const { mediaToDelete } = product

    await Promise.all(
      _.map(
        mediaToDelete,
        mediaToDeleteId =>
          new Promise((resolve, reject) => {
            const batchCode = `${batchPrefix}${mediaToDeleteId}`
            this.isBatchCompleted(batchCode, itemId).then(isBatchCompleted => {
              if (isBatchCompleted) {
                resolve(mediaToDeleteId)
                return
              }

              removeMedia(mediaToDeleteId)
                .then(() => {
                  product.media = _.filter(product.media, _item => _item.id !== mediaToDeleteId)
                  product.mediaToDelete = _.without(product.mediaToDelete, mediaToDeleteId)
                  this.completeBatch(batchCode, itemId)
                  resolve(mediaToDeleteId)
                })
                .catch(error => {
                  const status = error.data && error.data.status ? parseInt(error.data.status, 10) : 500

                  if (status === 404) {
                    product.media = _.filter(product.media, _item => _item.id !== mediaToDeleteId)
                    product.mediaToDelete = _.without(product.mediaToDelete, mediaToDeleteId)
                    this.completeBatch(batchCode, itemId)
                    resolve(mediaToDeleteId)
                  } else {
                    reject(error)
                  }
                })
            })
          })
      )
    )
  }

  _handleUploadMediaBatches = async (product, itemId) => {
    const batchPrefix = 'upload_media_'

    const media = _.filter(product.media, _media => _media.is_new)

    await Promise.all(
      _.map(
        media,
        _media =>
          new Promise((resolve, reject) => {
            const batchCode = `${batchPrefix}${_media.id}`
            this.isBatchCompleted(batchCode, itemId).then(isBatchCompleted => {
              if (isBatchCompleted) {
                resolve(_media.id)
                return
              }

              const file = urlToBlob(_media.file)
              tusUpload(file, {
                id: _media.id,
                file_name: _media.file_name,
                model_id: product.id,
                model_type: 'product',
                collection: _media.collection,
              })
                .then(() => {
                  // Remove no more useful values
                  product.media = _.map(product.media, _m => {
                    if (_m.id === _media.id) {
                      delete _m.is_new
                    }
                    return _m
                  })

                  this.completeBatch(batchCode, itemId)
                  resolve(_media.id)
                })
                .catch(error => reject(error))
            })
          })
      )
    )
  }
}

export default UpdateProductHandler
