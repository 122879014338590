import React, { Component } from 'react'
import { connect } from 'react-redux'
// import '@material/mwc-circular-progress'
import { CircularProgress } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Warning from '../../assets/svg/warning.svg'
import ErrorIcon from '../../assets/svg/error.svg'
import SuccessMarkIcon from '../../assets/svg/success_mark.svg'
import msgBoxMessagesMap from '../../../va-corejs-v3/utils/msgBoxMessagesMap'
import { pad } from '../../../va-corejs-v3/utils'
import { deleteScoring } from '../../../va-corejs-v3/actions/scoring'

import * as actionCreators from '../../store/actions'
import '../styles.scss'
import { httpGet } from '../../../va-corejs-v3/api'

const uuidv4 = require('uuid/v4')

const classNames = require('classnames')

class Msgbox extends Component {
  handleCloseMsgbox = () => {
    const { updateMsgboxMessage } = this.props
    updateMsgboxMessage('', false)
  }

  handleConfirm = async () => {
    const {
      msgboxMessage,
      data,
      updateLastRemovedItem,
      lockUnlockScoringOnServer,
      updateMsgboxMessage,
      removeClipboardFileOnServer,
      logout,
    } = this.props
    const { scoringId } = data
    switch (msgboxMessage) {
      case msgBoxMessagesMap.logout:
        await logout()
        window.location.href = '/'
        break
      case msgBoxMessagesMap.downloadAllMedia:
        httpGet(data.url)
        updateMsgboxMessage('', false)
        break
      case msgBoxMessagesMap.confirmDeleteProduct:
        updateMsgboxMessage(msgBoxMessagesMap.doubleConfirmDeleteProduct, {
          scoringId,
          par1: data.par1,
        })
        break
      case msgBoxMessagesMap.doubleConfirmDeleteProduct:
        await this.performDeleteActionOnClientSide(scoringId)
        await deleteScoring(scoringId)
        updateLastRemovedItem(scoringId)
        break
      case msgBoxMessagesMap.confirmLockUnlockScoring:
        await lockUnlockScoringOnServer(data.item)
        updateMsgboxMessage('', false)
        window.location.reload()
        break
      case msgBoxMessagesMap.syncError:
        this.createAndDownloadSyncStackFile()
        updateMsgboxMessage('', false)
        break
      case msgBoxMessagesMap.confirmDeleteProductAttachment:
        await removeClipboardFileOnServer(data.scoringTree, data.item)
        updateMsgboxMessage('', false)
        break
      default:
        break
    }
  }

  performDeleteActionOnClientSide = async id => {
    const { device, downloaded, removeDownloadedScorings, updateOfflineProductsList } = this.props

    await removeDownloadedScorings(downloaded.scorings, id)
    if (!device.isOnline) {
      updateOfflineProductsList()
    }
  }

  createAndDownloadSyncStackFile = () => {
    let now = new Date()
    now = `${now.getFullYear()}-${pad(now.getMonth() + 1, 2)}-${pad(now.getDate(), 2)}`
    const filename = `valueable_error_report_${now}.txt`

    const { data } = this.props
    let text = 'ValueApp experienced an error\n'
    text += '------------------------------------------------------\n'
    for (let i = 0; i < data.length; i += 1) {
      text += `${i} - ${data[i].time} - ${data[i].result} `
      if (data[i].e) {
        text += ` [ ${data[i].e} ]`
      }
      text += '\n'
    }

    const element = document.createElement('a')
    element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`)
    element.setAttribute('download', filename)

    element.style.display = 'none'
    document.body.appendChild(element)

    element.click()

    document.body.removeChild(element)
  }

  render() {
    const { texts, msgboxMessage, data } = this.props
    let buttons = []
    let msg1 = ''
    let msg2 = ''
    let error = false
    let showWarning = true
    let preparingDownload = false
    let publishStarted = false
    switch (msgboxMessage) {
      case msgBoxMessagesMap.error:
        error = true
        msg1 = texts.msgbox_error_title
        msg2 = data.message
        buttons = []
        break
      case msgBoxMessagesMap.logout:
        msg1 = texts.msgbox_logout_msg
        buttons = [
          { label: texts.validate, action: 'confirm' },
          { label: texts.cancel, action: 'close' },
        ]
        break
      case msgBoxMessagesMap.downloadAllMedia:
        msg1 = texts.msgbox_download_all_media_msg1.replace('@product_name', data.productName)
        msg2 = texts.msgbox_download_all_media_msg2
        buttons = [
          { label: 'OK', action: 'confirm' },
          { label: texts.cancel, action: 'close' },
        ]
        break
      case msgBoxMessagesMap.idbSizeExeeded:
        msg1 = texts.msgbox_idb_exeeded_msg_1
        msg2 = texts.msgbox_idb_exeeded_msg_2
        buttons = [{ label: texts.msgbox_idb_exeeded_button, action: 'close' }]
        break
      case msgBoxMessagesMap.offlineImageLimitator:
        msg1 = texts.msgbox_idb_exeeded_msg_1
        msg2 = texts.msgbox_idb_exeeded_msg_2
        buttons = [{ label: texts.msgbox_idb_exeeded_button, action: 'close' }]
        break
      case msgBoxMessagesMap.confirmDeleteProduct:
      case msgBoxMessagesMap.confirmDeleteProductAttachment:
        msg1 = texts.msgbox_confirm_delete_product
        msg2 = `${data.par1} ?`
        buttons = [
          { label: 'OK', action: 'confirm' },
          { label: texts.cancel, action: 'close' },
        ]
        break
      case msgBoxMessagesMap.doubleConfirmDeleteProduct:
        msg1 = texts.msgbox_double_confirm_delete_product
        msg2 = `${data.par1} ?`
        buttons = [
          { label: 'OK', action: 'confirm' },
          { label: texts.cancel, action: 'close' },
        ]
        break
      case msgBoxMessagesMap.confirmLockUnlockScoring:
        msg1 = data.item.is_locked
          ? texts.msgbox_confirm_unlock_product.replace('@par1', data.par1)
          : texts.msgbox_confirm_lock_product.replace('@par1', data.par1)
        buttons = [
          { label: 'OK', action: 'confirm' },
          { label: texts.cancel, action: 'close' },
        ]
        break
      case msgBoxMessagesMap.publishStarted:
        publishStarted = true
        showWarning = false
        msg1 = texts.publish_started
        buttons = []
        break
      case msgBoxMessagesMap.preparingDownload:
        preparingDownload = true
        showWarning = false
        msg1 = texts.preparing_download
        buttons = []
        break
      case msgBoxMessagesMap.preparingUpload:
        preparingDownload = true
        showWarning = false
        msg1 = texts.preparing_upload
        buttons = []
        break
      case msgBoxMessagesMap.syncError:
        msg1 = texts.msgbox_sync_error_title
        msg2 = []
        for (let i = 0; i < data.length; i += 1) {
          msg2.push(
            <li key={uuidv4()}>
              <span
                className={classNames({
                  li_text: true,
                })}
              >
                {data[i].result}
              </span>
            </li>
          )
        }
        /* msg2.push(
          <div key={uuidv4()}>
            <ul className="msgbox_ul">{msg2}</ul>
          </div>
        ) */
        buttons = [{ label: texts.msgbox_close_and_download_report, action: 'confirm' }]
        break
      default:
        break
    }

    if (error) {
      return (
        <div
          className={classNames({
            bottomDownContainer: true,
          })}
        >
          <div
            className={classNames({
              shell: true,
            })}
          >
            <div className="error_mark">
              <ErrorIcon width="30px" height="30px" />
            </div>
            <div style={{ width: '100%', marginLeft: 10, textAlign: 'center' }}>
              <div className="msg">{msg1}</div>
              <div className="msg_secondary" style={{ fontSize: '.9em' }}>
                <div dangerouslySetInnerHTML={{ __html: msg2 }} />
              </div>
            </div>
            <button key={uuidv4()} type="button" className="close_msgbox_btn" onClick={this.handleCloseMsgbox}>
              <CloseIcon />
            </button>
          </div>
        </div>
      )
    }

    if (publishStarted) {
      return (
        <div
          className={classNames({
            bottomDownContainer: true,
          })}
        >
          <div
            className={classNames({
              shell: true,
            })}
          >
            <div className="success_check_mark">
              <SuccessMarkIcon />
            </div>
            <div className="msg">{msg1}</div>
            <button key={uuidv4()} type="button" className="close_msgbox_btn" onClick={this.handleCloseMsgbox}>
              <CloseIcon />
            </button>
          </div>
        </div>
      )
    }

    if (preparingDownload) {
      return (
        <div
          className={classNames({
            bottomDownContainer: true,
          })}
        >
          <div
            className={classNames({
              shell: true,
            })}
          >
            <div className="circular_progress">
              {/* <mwc-circular-progress indeterminate /> */}
              <CircularProgress size={50} />
            </div>
            <div className="msg">{msg1}</div>
          </div>
        </div>
      )
    }

    if (msgboxMessage && msgboxMessage !== '') {
      return (
        <div
          className={classNames(
            {
              msgbox_container: true,
              grey_background: preparingDownload,
            },
            msgboxMessage.toLowerCase()
          )}
        >
          <div
            className={classNames({
              msgbox_shell: true,
              preparing_download: preparingDownload,
            })}
          >
            {showWarning && (
              <div className="msgbox_warning_container">
                <Warning className="msgbox_warning_svg" />
              </div>
            )}
            <div className="msgbox_message_container">
              <div
                className={classNames({
                  msgbox_message: true,
                  left_align: true,
                })}
              >
                <p>
                  {msg1}
                  <br />
                  {msg2}
                </p>
              </div>
            </div>
            <div className="msgbox_button_container">
              {buttons &&
                buttons.map(button => (
                  <button
                    key={uuidv4()}
                    type="button"
                    className="msgbox_btn"
                    onClick={button.action === 'confirm' ? this.handleConfirm : this.handleCloseMsgbox}
                  >
                    {button.label.toUpperCase()}
                  </button>
                ))}
            </div>
          </div>
        </div>
      )
    }

    return <></>
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
    downloaded: state.downloaded,
    msgboxMessage: state.msgbox.msgboxMessage,
    data: state.msgbox.data,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(Msgbox)
