import _ from 'lodash'
import storage from '../../storage'
import storageMap from '../../storage/storageMap'
import itemViewsMap from '../../../src/components/scoring_tree/helper/itemViewsMap'
import mediaCollectionsMap from '../../utils/mediaCollectionsMap'
import { calculate, createScorePanel } from '../../specializations'
import { caluclateCompletedPercentage } from '../../utils'
import nodeDefinitionTypeMap from '../../../src/components/scoring_tree/helper/nodeDefinitionTypeMap'
import syncQueue from '../../sync/syncQueue'
import { SYNC_ACTION_UPDATE_PRODUCT, SYNC_ACTION_UPDATE_SCORING_TREE } from '../../sync/consts'

export async function generateScoringTreeDataStructureForRedux(id) {
  const scoring = await storage.get(storageMap.scoring, id) // check this get

  let nodeDefsObj = {}
  let scopeProps = []
  let localesList = []

  if (!scoring) {
    return false
  }

  if (scoring) {
    const template = await storage.get(storageMap.template, scoring.template_id)
    const { locales } = template
    nodeDefsObj = template.node_definitions
    localesList = locales

    const scope = await storage.get(storageMap.scope, template.scope.id)
    scopeProps = scope.props
  }
  const defKeys = Object.keys(nodeDefsObj)

  const completedPercentage = caluclateCompletedPercentage(defKeys, nodeDefsObj, scoring.nodes)

  let itemsFather = {}
  if (nodeDefsObj && scoring && scoring.nodes && scoring.itemsFatherId) {
    itemsFather = nodeDefsObj[scoring.itemsFatherId]
  } else if (nodeDefsObj) {
    const treeMap = global.env.treeMap.split(',')
    treeMap.pop() // Remove criteria
    treeMap.pop() // Remove items
    itemsFather = nodeDefsObj[defKeys[treeMap.length - 1]]
  }

  // Count Media
  scoring.mediaCount = await storage.count(storageMap.media, id, 'collection', mediaCollectionsMap.node_pictures)

  // Count Media Forecast
  if (scoring.project_mode) {
    scoring.mediaForecastCount = await storage.count(
      storageMap.media,
      id,
      'collection',
      mediaCollectionsMap.forecast_node_pictures
    )

    scoring.mediaCount += scoring.mediaForecastCount
  }

  // Count Comments
  const commentsList = await storage.getAllByKey(storageMap.comment, 'scoring_id', id)
  scoring.commentsCount = commentsList.length

  // Add digital score
  const scoreDigital = await storage.get(storageMap.extra_data, `scoring_${scoring.id}_score_digital`)

  if (scoreDigital && scoreDigital.data) scoring.score_digital = scoreDigital.data

  await storage.update(storageMap.scoring, scoring)

  return {
    scoring,
    nodeDefsObj,
    localesList,
    defKeys,
    scopeProps,
    itemsFather,
    completedPercentage,
  }
}

export async function getScoringMediaById(scoringId, mediaCollection) {
  const media = await storage.getMedia(scoringId, mediaCollection)
  return media
}

export async function updateScoringIntoLocalStorage(scoringId, nodeId, isItemsFather, scoreSet) {
  const scoring = await storage.get(storageMap.scoring, scoringId)

  const template = await storage.get(storageMap.template, scoring.template_id)
  const nodeDef = template.node_definitions[nodeId]

  if (nodeDef.type === 'item') {
    let branchScored = false
    const sons = template.node_definitions[nodeId].children_ids
    for (let i = 0; i !== sons.length; i += 1) {
      const itm = sons[i]
      if (scoring.nodes[itm].scored && !scoring.nodes[itm].is_default) {
        branchScored = true
      }
    }
    scoring.nodes[nodeId].scored = branchScored
    const father = template.node_definitions[nodeDef.parent_id]
    scoring.nodes[father.id].scored = branchScored
  }

  if (!scoreSet) scoring.nodes[nodeId].isExpanded = !scoring.nodes[nodeId].isExpanded
  else scoring.nodes[nodeId][scoreSet].isExpanded = !scoring.nodes[nodeId][scoreSet].isExpanded

  scoring.activeItemId = nodeId
  if (isItemsFather) {
    scoring.itemsFatherId = nodeId
  }
  await storage.update(storageMap.scoring, scoring)
  return scoring
}

export async function updateScoringTreeAnalyticsIntoLocalStorage(scoringId, analytics) {
  const scoring = await storage.get(storageMap.scoring, scoringId)
  scoring.analytics = analytics
  await storage.update(storageMap.scoring, scoring)
  return scoring.analytics
}

export async function getScoringTreeAnalyticsFromLocalStorage(scoringId) {
  const scoring = await storage.get(storageMap.scoring, scoringId)
  return scoring.analytics
}

export async function getRelatedItemsFatherNodeDefFromStorageByCurrentNodeType(scoringId, nodeId) {
  const scoring = await storage.get(storageMap.scoring, scoringId)
  const template = await storage.get(storageMap.template, scoring.template_id)
  const node = template.node_definitions[nodeId]

  let itemsFather = null

  if (node.type === 'perimeter') {
    const familyId = node.children_ids[0]
    const familyNode = template.node_definitions[familyId]
    const subFamilyId = familyNode.children_ids[0]
    itemsFather = template.node_definitions[subFamilyId]
  } else if (node.type === nodeDefinitionTypeMap.family) {
    const subFamilyId = node.children_ids[0]
    itemsFather = template.node_definitions[subFamilyId]
  } else if (node.type === nodeDefinitionTypeMap.subfamily) {
    itemsFather = node
  } else if (node.type === nodeDefinitionTypeMap.item) {
    const subFamilyId = node.parent_id
    itemsFather = template.node_definitions[subFamilyId]
  } else if (node.type === nodeDefinitionTypeMap.criterion) {
    const itemId = node.parent_id
    const itemNode = template.node_definitions[itemId]
    const subFamilyId = itemNode.parent_id
    itemsFather = template.node_definitions[subFamilyId]
  }

  return itemsFather
}

function updateEnableDisableParentsRecursively(scoringTree, parentNode, modifiedNodesIds, scoreSet) {
  if (parentNode) {
    let isAllDisabled = true

    for (let i = 0; i < parentNode.children_ids.length; i += 1) {
      if (
        (!scoreSet && scoringTree.scoring.nodes[parentNode.children_ids[i]].is_enabled) ||
        (scoreSet &&
          scoringTree.scoring.nodes[parentNode.children_ids[i]][scoreSet] &&
          scoringTree.scoring.nodes[parentNode.children_ids[i]][scoreSet].is_enabled)
      ) {
        isAllDisabled = false
      }
    }

    if (!scoreSet) {
      scoringTree.scoring.nodes[parentNode.id].is_enabled = !isAllDisabled
    } else if (scoringTree.scoring.nodes[parentNode.id][scoreSet]) {
      scoringTree.scoring.nodes[parentNode.id][scoreSet].is_enabled = !isAllDisabled
    }

    modifiedNodesIds[parentNode.id] = true

    if (parentNode.parent_id !== undefined && parentNode.parent_id !== null) {
      updateEnableDisableParentsRecursively(
        scoringTree,
        scoringTree.nodeDefsObj[parentNode.parent_id],
        modifiedNodesIds,
        scoreSet
      )
    }
  }
}

function toggleChildrenNodes(scoringTree, currentNodeDefinition, checked, modifiedNodesIds) {
  _.each(scoringTree.defKeys, key => {
    const _nodeDefinition = scoringTree.nodeDefsObj[key]
    if (_nodeDefinition._left > currentNodeDefinition._left && _nodeDefinition._right < currentNodeDefinition._right) {
      // Normal scores
      scoringTree.scoring.nodes[_nodeDefinition.id].is_enabled = checked

      // Forecast score set
      if (scoringTree.scoring.nodes[_nodeDefinition.id].forecast) {
        scoringTree.scoring.nodes[_nodeDefinition.id].forecast.is_enabled =
          checked && !scoringTree.scoring.nodes[_nodeDefinition.id].is_default
      }

      modifiedNodesIds[_nodeDefinition.id] = true
    }
  })
}

export async function updateEnableDisableNodesIntoLocalStorage(scoringTree, node, checked, scoreSet) {
  const nodesToSync = []
  const nodeDef = scoringTree.nodeDefsObj[node.id]

  // Enable/Disable selected node
  const dataNode = scoringTree.scoring.nodes[node.id]

  if (!scoreSet) dataNode.is_enabled = checked
  else if (dataNode[scoreSet]) dataNode[scoreSet].is_enabled = checked

  nodesToSync.push({ ...dataNode })

  // Get all childs nodes to enable or disable
  const modifiedNodesIds = {}

  // disable/enable all child for all scoreSets
  toggleChildrenNodes(scoringTree, nodeDef, checked, modifiedNodesIds)

  updateEnableDisableParentsRecursively(
    scoringTree,
    scoringTree.nodeDefsObj[node.parent_id],
    modifiedNodesIds,
    scoreSet
  )

  const { scoring, nodeDefsObj, defKeys } = scoringTree
  const { nodes } = scoring
  const duplicatedNodes = { ...nodes }

  await calculate(scoring, nodeDefsObj, duplicatedNodes)

  const scorePanel = await createScorePanel(scoring, duplicatedNodes, nodeDefsObj)

  const modifiedNodesIdsKeys = Object.keys(modifiedNodesIds)
  modifiedNodesIdsKeys.map(async nodeDefinitionId => {
    nodesToSync.push({ ...duplicatedNodes[nodeDefinitionId] })
  })

  const completedPercentage = caluclateCompletedPercentage(defKeys, nodeDefsObj, duplicatedNodes)

  scoring.score_panel = scorePanel
  scoring.nodes = duplicatedNodes

  await storage.update(storageMap.scoring, scoring)

  await syncQueue.add(SYNC_ACTION_UPDATE_SCORING_TREE, {
    product_id: scoringTree.scoring.id,
    nodes: nodesToSync,
  })

  await syncQueue.add(SYNC_ACTION_UPDATE_PRODUCT, {
    id: scoringTree.scoring.id,
  })

  return { completedPercentage, scorePanel }
}

export async function applyExpandedStatusToItemsAndCriteriaOnStorage(scoringObj, itemView, parentNodeId) {
  const scoring = await storage.get(storageMap.scoring, scoringObj.id)
  const parsedItemView = parseInt(itemView, 10)
  const templateId = scoring.template_id
  const template = await storage.get(storageMap.template, templateId)
  const parentNode = template.node_definitions[parentNodeId]
  const children = parentNode.children_ids

  switch (parsedItemView) {
    case itemViewsMap.itemsExpanded:
    case itemViewsMap.allExpanded:
      for (let i = 0; i !== children.length; i += 1) {
        // Expand items
        scoring.nodes[children[i]].isExpanded = true

        // Expand/collapse criteria
        const criteria = template.node_definitions[children[i]].children_ids
        for (let x = 0; x !== criteria.length; x += 1) {
          scoring.nodes[criteria[x]].isExpanded = parsedItemView === itemViewsMap.allExpanded
          if (scoring.nodes[criteria[x]].forecast)
            scoring.nodes[criteria[x]].forecast.isExpanded = parsedItemView === itemViewsMap.allExpanded
        }
      }
      break
    case itemViewsMap.unscored:
      for (let i = 0; i !== children.length; i += 1) {
        if (
          scoring.nodes[children[i]].percentageScored !== undefined &&
          scoring.nodes[children[i]].percentageScored < 100
        ) {
          // Expand unscored items and criteria
          scoring.nodes[children[i]].isExpanded = true
          const criteria = template.node_definitions[children[i]].children_ids
          for (let x = 0; x !== criteria.length; x += 1) {
            scoring.nodes[criteria[x]].isExpanded = true
          }
        }
      }
      break
    case itemViewsMap.toReview:
      for (let i = 0; i !== children.length; i += 1) {
        const criteria = template.node_definitions[children[i]].children_ids

        if (scoring.nodes[children[i]].to_review === true) {
          // Expand toReview items and criteria
          scoring.nodes[children[i]].isExpanded = true
          for (let x = 0; x !== criteria.length; x += 1) {
            scoring.nodes[criteria[x]].isExpanded = true
          }
        } else {
          for (let x = 0; x !== criteria.length; x += 1) {
            if (scoring.nodes[criteria[x]].to_review === true) {
              scoring.nodes[children[i]].isExpanded = true
              scoring.nodes[criteria[x]].isExpanded = true
            }
          }
        }
      }
      break
    default:
      for (let i = 0; i !== children.length; i += 1) {
        // Collapse all items and criteria
        scoring.nodes[children[i]].isExpanded = false

        const criteria = template.node_definitions[children[i]].children_ids
        for (let x = 0; x !== criteria.length; x += 1) {
          scoring.nodes[criteria[x]].isExpanded = false
        }
      }
      break
  }

  await storage.update(storageMap.scoring, scoring)

  const response = {
    scoring,
  }

  return response
}

export async function updateItemDefaultValueOnLocalStorage(scoringTree, nodeDefId) {
  let nodeDefKey = ''
  let dataNode = { id: false }
  const nodesToModify = []

  for (let i = 0; i < scoringTree.defKeys.length; i += 1) {
    nodeDefKey = scoringTree.defKeys[i]
    const node = scoringTree.nodeDefsObj[nodeDefKey]
    if (node.parent_id === nodeDefId) {
      dataNode = scoringTree.scoring.nodes[nodeDefKey]
      dataNode.score = node.default_score
      dataNode.scored = true
      dataNode.is_default = false
      nodesToModify.push(dataNode)
    }
  }

  const template = await storage.get(storageMap.template, scoringTree.scoring.template_id)

  const fNodesToSync = []
  let fNode = {}
  let nodeDef = template.node_definitions[nodeDefId]
  while (nodeDef && nodeDef.parent_id) {
    fNode = scoringTree.scoring.nodes[nodeDef.parent_id]
    if (fNode) {
      fNodesToSync.push({ ...fNode })
    }
    nodeDef = template.node_definitions[nodeDef.parent_id]
  }

  await syncQueue.add(SYNC_ACTION_UPDATE_SCORING_TREE, {
    product_id: scoringTree.scoring.id,
    nodes: fNodesToSync,
  })

  await storage.update(storageMap.scoring, scoringTree.scoring)

  await syncQueue.add(SYNC_ACTION_UPDATE_PRODUCT, {
    id: scoringTree.scoring.id,
  })

  await syncQueue.add(SYNC_ACTION_UPDATE_SCORING_TREE, {
    product_id: scoringTree.scoring.id,
    nodes: nodesToModify,
  })

  return scoringTree.scoring
}

export async function updateToReviewValueOnLocalStorage(scoringId, nodeDefId, toReview, scoreSet) {
  const scoring = await storage.get(storageMap.scoring, scoringId)

  if (!scoreSet) scoring.nodes[nodeDefId].to_review = toReview
  else if (scoring.nodes[nodeDefId][scoreSet]) scoring.nodes[nodeDefId][scoreSet].to_review = toReview

  await storage.update(storageMap.scoring, scoring)
  await syncQueue.add(SYNC_ACTION_UPDATE_SCORING_TREE, {
    product_id: scoring.id,
    nodes: [scoring.nodes[nodeDefId]],
  })
  return scoring
}

export async function updateScorePanelOnLocalStorage(scoringTree, shouldSync = true) {
  const { scoring } = scoringTree

  scoring.score_panel = await createScorePanel(scoring, scoring.nodes, scoringTree.nodeDefsObj)
  await storage.update(storageMap.scoring, scoring)

  if (shouldSync) {
    await syncQueue.add(SYNC_ACTION_UPDATE_PRODUCT, {
      id: scoring.id,
    })
  }

  return scoring
}

export async function updateNodeScoreValueOnLocalStorage(scoringTree, nodeDefId, score, isDefault, scoreSet) {
  const scoring = await storage.get(storageMap.scoring, scoringTree.scoring.id)

  if (scoreSet && !scoring.nodes[nodeDefId][scoreSet]) scoring.nodes[nodeDefId][scoreSet] = {}

  if (!scoreSet) {
    scoring.nodes[nodeDefId].score = parseFloat(score)
    scoring.nodes[nodeDefId].is_default = isDefault
  } else if (scoring.nodes[nodeDefId][scoreSet]) {
    scoring.nodes[nodeDefId][scoreSet].is_default = isDefault
    scoring.nodes[nodeDefId][scoreSet].score = parseFloat(score)
    scoring.nodes[nodeDefId][scoreSet].is_enabled = true
  }

  //-----
  // if score benchmark and forecast is default forecast is disabled
  /* if (isDefault && scoring.nodes[nodeDefId].forecast_is_default)
    scoring.nodes[nodeDefId].forecast_is_enabled = false

  // if score benchmark is scored and forecast is default
  // then use benchmark value for forcast
  if (!scoreSet && !isDefault && scoring.nodes[nodeDefId].forecast_is_default) {
    scoring.nodes[nodeDefId].forecast_score = parseFloat(score)
    scoring.nodes[nodeDefId].forecast_is_enabled = true
  }

  if (
    scoreSet &&
    scoreSet === 'forecast' &&
    isDefault &&
    !scoring.nodes[nodeDefId].is_default
  ) {
    scoring.nodes[nodeDefId].forecast_score = scoring.nodes[nodeDefId].score
    scoring.nodes[nodeDefId].forecast_is_enabled = true
  } */
  //-----

  const template = await storage.get(storageMap.template, scoring.template_id)

  const completedPercentage = caluclateCompletedPercentage(scoringTree.defKeys, scoringTree.nodeDefsObj, scoring.nodes)

  await calculate(scoringTree.scoring, scoringTree.nodeDefsObj, scoring.nodes)
  scoring.score_panel = await createScorePanel(scoring, scoring.nodes, scoringTree.nodeDefsObj)
  await storage.update(storageMap.scoring, scoring)

  await syncQueue.add(SYNC_ACTION_UPDATE_PRODUCT, {
    id: scoring.id,
  })

  const nodesToSync = []

  let node = scoring.nodes[nodeDefId]
  nodesToSync.push(node)

  let nodeDef = template.node_definitions[nodeDefId]
  while (nodeDef && nodeDef.parent_id) {
    node = scoring.nodes[nodeDef.parent_id]
    if (node) {
      nodesToSync.push(node)
    }
    nodeDef = template.node_definitions[nodeDef.parent_id]
  }

  await syncQueue.add(SYNC_ACTION_UPDATE_SCORING_TREE, {
    product_id: scoring.id,
    nodes: nodesToSync,
  })

  return { scoring, completedPercentage }
}

export async function updateActiveItemOnLocalStorage(
  scoringId,
  subFamilyId,
  familyId,
  perimeterId,
  itemsFatherId,
  activeItemId
) {
  const scoring = await storage.get(storageMap.scoring, scoringId)
  scoring.itemsFatherId = itemsFatherId
  scoring.activeItemId = activeItemId
  const { nodes } = scoring
  nodes[subFamilyId].isExpanded = true
  nodes[familyId].isExpanded = true
  nodes[perimeterId].isExpanded = true
  nodes[activeItemId].isExpanded = true

  /* VAV3-970 https://valueablesas.atlassian.net/browse/VAV3-970
  const template = await get(storageMap.template, scoring.template_id)
  const brothers = template.node_definitions[subFamilyId].children_ids
  for (let i = 0; i !== brothers.length; i += 1) {
    const itm = brothers[i]
    if (itm !== activeItemId) {
      scoring.nodes[itm].isExpanded = false
    }
  }
  */

  await storage.update(storageMap.scoring, scoring)

  return scoring
}

export async function updateScoringStatusAndProps(scoringId, status, props) {
  const scoring = await storage.get(storageMap.scoring, scoringId)
  scoring.status = status
  scoring.props = props

  await storage.update(storageMap.scoring, scoring)

  return scoring
}

export async function getScoringById(scoringId) {
  const scoring = await storage.get(storageMap.scoring, scoringId)
  return scoring
}
