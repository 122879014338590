/*-----------------------------------------------------*/
// Enable Project Mode
/*-----------------------------------------------------*/

export function isProjectModeEnabled(scoring) {
  const enabledInConf =
    process.env && process.env.projectModeEnabled && JSON.parse(process.env.projectModeEnabled) === true
  const enabledInProduct = scoring && scoring.project_mode

  if (!scoring) return enabledInConf
  return enabledInConf && enabledInProduct
}

/*-----------------------------------------------------*/
// Normalize Media and Comments count
// excluding or including forecast media and comments
/*-----------------------------------------------------*/

export function normalizeProjectModeCount(scoring, nodeDefId, action, active) {
  let count

  const obj = nodeDefId ? scoring.nodes[nodeDefId] : scoring

  if (!obj) return false

  count = obj[`${action}Count`]

  // in some situations when product has project mode data
  // but it's not allowed to shoe data
  const hideProjectModeData = !isProjectModeEnabled(scoring) && (!nodeDefId || obj.forecast)

  if (active || hideProjectModeData) {
    let forecastCount = 0

    if (nodeDefId && obj.forecast) forecastCount = obj.forecast[`${action}Count`]
    if (!nodeDefId) forecastCount = obj[`${action}ForecastCount`]

    count = forecastCount ? obj[`${action}Count`] - forecastCount : obj[`${action}Count`]
  }

  return count
}

/*-----------------------------------------------------*/
// Normalize Props
// excluding or including attribute forecast value
// in Project Mode scoring
/*-----------------------------------------------------*/

export function normalizeProjectModeProp(scoring, propObj, propAttr, propDefault) {
  let normProp = propDefault

  // check scoring props is disabled
  if (typeof propObj[propAttr] !== 'undefined' && propObj[propAttr] !== null) normProp = propObj[propAttr]

  // check scroing props in project mode is disabled
  if (isProjectModeEnabled(scoring) && propObj.forecast && typeof propObj.forecast[propAttr] !== 'undefined')
    normProp = propObj.forecast[propAttr]

  return normProp
}
