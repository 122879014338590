import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'

import ScoringTextProp from './props/scoringTextProp'
import ScoringSelectProp from './props/scoringSelectProp'
import ScoringDataListProp from './props/scoringDataListProp'
import ScoringDateProp from './props/scoringDateProp'
import ScoringDistanceProp from './props/scoringDistanceProp'
import ScoringPriceProp from './props/scoringPriceProp'
import ScoringTireSizeProp from './props/scoringTireSizeProp'
import ScoringWheelSizeProp from './props/scoringWheelSizeProp'

import scoringPropsTypeMap from './helper/scoringPropsTypeMap'

const classNames = require('classnames')

export class ScoringDetailedPropsColumn extends React.Component {
  render() {
    const {
      retrieveScoringPropFromRedux,
      list,
      handleDateValueChanged,
      handleTextValueChanged,
      handleSelectValueChanged,
      handleDataListValueChanged,
    } = this.props

    return (
      <div>
        {list.map(prop => {
          const { values, slug, type, label, props } = prop
          return (
            <div
              className={classNames({
                ns_detailed_prop_container: true,
              })}
              key={slug}
            >
              {type === scoringPropsTypeMap.datalist && (
                <ScoringDataListProp
                  slug={slug}
                  type={type}
                  label={label}
                  values={values}
                  onChangeFunction={handleDataListValueChanged}
                  retrieveScoringPropFromRedux={retrieveScoringPropFromRedux}
                />
              )}
              {type === scoringPropsTypeMap.select && (
                <ScoringSelectProp
                  slug={slug}
                  type={type}
                  label={label}
                  values={values}
                  onChangeFunction={handleSelectValueChanged}
                  retrieveScoringPropFromRedux={retrieveScoringPropFromRedux}
                />
              )}
              {(type === scoringPropsTypeMap.text || type === scoringPropsTypeMap.number) && (
                <ScoringTextProp
                  slug={slug}
                  type={type}
                  label={label}
                  onChangeFunction={handleTextValueChanged}
                  retrieveScoringPropFromRedux={retrieveScoringPropFromRedux}
                />
              )}
              {type === scoringPropsTypeMap.date && (
                <ScoringDateProp
                  slug={slug}
                  type={type}
                  label={label}
                  onChangeFunction={handleDateValueChanged}
                  retrieveScoringPropFromRedux={retrieveScoringPropFromRedux}
                />
              )}
              {type === scoringPropsTypeMap.distance && (
                <ScoringDistanceProp
                  slug={slug}
                  type={type}
                  label={label}
                  props={props}
                  retrieveScoringPropFromRedux={retrieveScoringPropFromRedux}
                />
              )}
              {type === scoringPropsTypeMap.price && (
                <ScoringPriceProp
                  slug={slug}
                  type={type}
                  label={label}
                  props={props}
                  retrieveScoringPropFromRedux={retrieveScoringPropFromRedux}
                />
              )}
              {type === scoringPropsTypeMap.tire_size && (
                <ScoringTireSizeProp
                  slug={slug}
                  type={type}
                  label={label}
                  props={props}
                  retrieveScoringPropFromRedux={retrieveScoringPropFromRedux}
                />
              )}
              {type === scoringPropsTypeMap.wheel_size && (
                <ScoringWheelSizeProp
                  slug={slug}
                  type={type}
                  label={label}
                  props={props}
                  retrieveScoringPropFromRedux={retrieveScoringPropFromRedux}
                />
              )}
            </div>
          )
        })}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    texts: state.texts.values,
    scoring: state.scoring,
    status: state.status,
  }
}

export default connect(mapStateToProps, actionCreators)(ScoringDetailedPropsColumn)
