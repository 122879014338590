import React from 'react'
import { connect } from 'react-redux'

import { graphql, StaticQuery } from 'gatsby'

import * as actionCreators from '../../../store/actions'
import authPagesMap from './authPagesMap'

const classNames = require('classnames')

export class PassComponent extends React.Component {
  render() {
    const { authProcess, updatePassword, texts, handleExecuteLogin } = this.props

    const emptyPassword = authProcess.passwordValidation.isEmpty && authProcess.showPasswordError
    const passwordNotFound =
      !authProcess.passwordValidation.isEmpty &&
      authProcess.passwordValidation.checkedIfExists &&
      !authProcess.passwordValidation.exists &&
      authProcess.showPasswordError

    const fieldErrorVisible = emptyPassword || passwordNotFound

    return (
      <StaticQuery
        query={graphql`
          query {
            userIcon: file(relativePath: { regex: "/assets/svg/20px-username.svg/" }) {
              publicURL
            }
          }
        `}
        render={data => (
          <div
            className={
              authProcess.page === authPagesMap.password
                ? 'login_form_inner_container_active slide_left'
                : 'login_form_inner_container_inactive'
            }
          >
            <div className="login_form_username">
              <div>
                <img src={data.userIcon.publicURL} alt="user-icon" />{' '}
                <span className="login-form-user-value">{authProcess.userEmail}</span>
              </div>
            </div>
            <div style={{ position: 'relative' }}>
              {emptyPassword && <div className="login_form_field_error">{texts.auth_password_empty}</div>}
              {passwordNotFound && <div className="login_form_field_error">{texts.auth_password_not_found}</div>}
              <div
                className={classNames({
                  login_form_input_container: true,
                  login_form_pass_input: true,
                  input_with_error: fieldErrorVisible,
                })}
              >
                <input
                  type="password"
                  placeholder=" "
                  value={authProcess.password}
                  onChange={e => {
                    updatePassword(e)
                  }}
                  onKeyPress={e => {
                    const code = e.which ? e.which : e.keyCode
                    if (code === 13) {
                      handleExecuteLogin()
                    }
                  }}
                />
                <span
                  className={classNames({
                    floating_label: !fieldErrorVisible,
                    static_label: fieldErrorVisible,
                  })}
                >
                  Password
                </span>
              </div>
            </div>
          </div>
        )}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    authProcess: state.authentication.authProcess,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(PassComponent)
