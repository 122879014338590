import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../store/actions'

import CarouselItem from './carouselItem'

const uuidv4 = require('uuid/v4')

class CarouselItems extends Component {
  render() {
    const { activeScore, productsList, carouselPinned } = this.props
    let { carouselIndex } = this.props

    // IMPORTANT!!! Add 2 empty items before and after and move carouseIndex 2 steps forward
    // -----------------------------------------------
    const productListToShow = [...productsList]
    const emptyItem = {
      name: '',
      cover: '',
      score: false,
    }
    productListToShow.unshift(emptyItem)
    productListToShow.unshift(emptyItem)
    productListToShow.push(emptyItem)
    productListToShow.push(emptyItem)
    carouselIndex += 2
    // -----------------------------------------------

    function drawItems() {
      if (activeScore === false || carouselIndex === false) {
        return ''
      }
      const itemsToReturn = []
      let level = 0
      for (let i = carouselIndex - 2; i < carouselIndex + 3; i += 1) {
        let index = i
        if (i < 0) {
          index = productListToShow.length + i
        } else if (i >= productListToShow.length) {
          index = i % productListToShow.length
        }
        level = carouselIndex - i
        itemsToReturn.push(
          <CarouselItem
            key={uuidv4()}
            itemData={productListToShow[index]}
            level={level}
            carouselPinned={carouselPinned}
            picIndex={i - 2}
          />
        )
      }
      return itemsToReturn
    }

    return <>{drawItems()}</>
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    device: state.device,
    texts: state.texts.values,
    activeScore: state.interRanking.activeScore,
    carouselIndex: state.interRanking.carouselIndex,
  }
}

export default connect(mapStateToProps, actionCreators)(CarouselItems)
