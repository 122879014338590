import _ from 'lodash'
import storage from '../../storage'
import storageMap from '../../storage/storageMap'

const uuidv4 = require('uuid/v4')

class DefaultActionHandler {
  #action

  #async

  setAction = action => {
    this.#action = action
  }

  getAction = () => {
    return this.#action
  }

  setAsync = async => {
    this.#async = async
  }

  getAsync = () => {
    return typeof this.#async === 'boolean' ? this.#async : true
  }

  // The logic behind:
  // There are "single" actions and "batch" actions.
  // In the "single" actions only one entity is processed in the sync HTTP request.
  // In the "batch" actions, multiple entities can be processed in the sync HTTP request.
  // For the "batch" actions, before adding them to the sync queue, it is necessary to verify
  // whether the current entity could be appended to an already existant element in the queue.
  // For instance, before adding a "delete comment" action, I haver to check if in the queue
  // already exists another "delete comment" action for the same product_id.
  async add(data) {
    return storage.put(storageMap.sync_queue_v2, {
      id: uuidv4(),
      action: this.getAction(),
      added_at: Date.now(),
      async: this.getAsync(),
      data,
    })
  }

  // eslint-disable-next-line no-unused-vars
  process = async item => {
    // This method needs to be extended
  }

  completeBatch = async (code, itemId) => {
    const item = await storage.get(storageMap.sync_queue_v2, itemId)

    item.batches = item.batches ?? {}
    item.batches[code] = {
      completed: true,
    }

    return storage.update(storageMap.sync_queue_v2, item)
  }

  isBatchCompleted = async (code, itemId) => {
    const item = await storage.get(storageMap.sync_queue_v2, itemId)

    return _.get(item.batches, [code, 'completed'])
  }
}

export default DefaultActionHandler
