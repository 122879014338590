import React from 'react'
import { connect } from 'react-redux'

import _ from 'lodash'
import * as actionCreators from '../../../../store/actions'
import screenTypesMap from '../../../../layout/_parts/screenTypesMap'
import { getMediaBlobUrl } from '../../../../../va-corejs-v3/api'

const classNames = require('classnames')

export class ScoringBrandSelector extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      prop: null,
      brandTextValue: false,
      brandLogoUrl: null,
      filteredList: [],
    }
  }

  setBrandValues = async () => {
    const { environment, slug, retrieveScoringPropFromRedux, values } = this.props
    const prop = retrieveScoringPropFromRedux(slug)

    this.setState({
      prop,
      brandTextValue: !prop ? '' : prop.value.body[environment.defaultLang],
    })

    if (prop && prop.value.slug !== null && values.length > 0) {
      const brandMedia = values.filter(x => x.slug === prop.value.slug)[0].media
      if (brandMedia && brandMedia.length > 0) {
        this.setState({
          brandLogoUrl: await getMediaBlobUrl(brandMedia[0].url),
        })
      }
    }
  }

  setFilteredList = async () => {
    const { environment, values } = this.props
    const { brandTextValue } = this.state

    const filteredList = values.filter(value =>
      value.body[environment.defaultLang].toLowerCase().includes(brandTextValue.toLowerCase())
    )

    // Get brand logos blob urls
    const promises = []
    _.each(filteredList, item => {
      if (item.media && item.media.length) {
        promises.push(
          new Promise((resolve, reject) => {
            getMediaBlobUrl(item.media[0].url)
              .then(response => {
                resolve({
                  slug: item.slug,
                  url: response,
                })
              })
              .catch(error => {
                reject(error)
              })
          })
        )
      }
    })

    const results = await Promise.all(promises)
    const finalList = _.map(filteredList, item => {
      const result = _.find(results, r => r.slug === item.slug)
      item.brandLogoUrl = result ? result.url : null
      return item
    })
    // End get logos blob urls

    this.setState({ filteredList: finalList })
  }

  componentDidMount = async () => {
    await this.setBrandValues()
    await this.setFilteredList()
  }

  componentDidUpdate = async (prevProps, prevState) => {
    const { environment, slug, values, retrieveScoringPropFromRedux } = this.props
    const prop = retrieveScoringPropFromRedux(slug)

    if (
      prevProps.slug !== slug ||
      prevProps.values !== values ||
      environment.lang !== prevProps.environment.lang ||
      prevState.prop !== prop
    ) {
      await this.setBrandValues()
    }

    const { brandTextValue } = this.state

    if (!_.isEqual(prevProps.values, values) || prevState.brandTextValue !== brandTextValue) {
      await this.setFilteredList()
    }
  }

  render() {
    const {
      texts,
      mandatory,
      label,
      slug,
      type,
      scoring,
      setScoringPropValue,
      setBrandFocused,
      environment,
      device,
    } = this.props

    const { brandTextValue, brandLogoUrl, prop } = this.state

    function handleBrandValueChange(e) {
      const { locales } = environment
      const body = {}
      locales.forEach(locale => {
        const { code } = locale
        body[code] = e.target.value
      })

      const value = {
        slug: null,
        body,
      }
      setScoringPropValue(scoring.newScoringId, slug, type, label, value)
    }

    function handleBrandFocused() {
      setBrandFocused(true)
    }

    function handleBrandUnfocused() {
      setBrandFocused(false)
    }

    function handleSuggestionSelected(suggestion) {
      const value = {
        slug: suggestion.slug,
        body: suggestion.body,
      }

      setScoringPropValue(scoring.newScoringId, slug, type, label, value, false)
      setBrandFocused(false)
    }

    const BrandLogo = () => {
      if (brandLogoUrl && prop && prop.value.slug !== null) {
        const html = []
        html.push(<img key={prop.value.slug} src={brandLogoUrl} alt="brand-logo" />)
        return html
      }

      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 30 30">
          <circle cx="15" cy="15" r="15" fill="#E9E9EA" fillRule="evenodd" transform="rotate(-180 15 15)" />
        </svg>
      )
    }

    const FilteredBrandSuggestions = () => {
      const { filteredList } = this.state

      return (
        <div
          className={classNames({
            ns_brand_suggestions_container: filteredList.length > 0,
            isSafari: device.isSafari,
            isFirefox: device.isFirefox,
            desk: device.screenType === screenTypesMap.desk,
            ipadh: device.screenType === screenTypesMap.iPadH,
            ipadv: device.screenType === screenTypesMap.iPadV,
            iphonexr: device.screenType === screenTypesMap.iPhoneXR,
            iphonese: device.screenType === screenTypesMap.iPhoneSE,
          })}
        >
          {filteredList.map(value => {
            return (
              <div
                className={classNames({ ns_brand_suggestion_itm: true })}
                key={value.slug}
                role="button"
                tabIndex={0}
                onClick={() => {
                  this.setState({
                    brandTextValue: value.body[environment.defaultLang],
                  })
                  handleSuggestionSelected(value)
                }}
                onKeyPress={() => {
                  this.setState({
                    brandTextValue: value.body[environment.defaultLang],
                  })
                  handleSuggestionSelected(value)
                }}
              >
                {value.brandLogoUrl && (
                  <img
                    className={classNames({ ns_brand_suggestion_logo: true })}
                    src={value.brandLogoUrl}
                    alt="brand-logo"
                  />
                )}

                <div className={classNames({ ns_brand_suggestion_name: true })}>
                  {value.body[environment.defaultLang]}
                </div>
              </div>
            )
          })}
        </div>
      )
    }

    return (
      <>
        <div className={classNames({ ns_prop_label: true })}>{label[environment.lang]}</div>
        <div className={classNames({ ns_brand_selector_container: true })}>
          <div
            className={classNames({
              ns_brand_selector: true,
              ns_focused_prop: scoring.isBrandFocused,
              ns_prop: true,
            })}
          >
            <div className={classNames({ ns_brand_icon: true })}>
              <BrandLogo />
            </div>
            <input
              className={classNames({ ns_brand_input: true })}
              type="text"
              value={brandTextValue}
              onChange={e => {
                this.setState({ brandTextValue: e.target.value })
                handleBrandValueChange(e)
              }}
              placeholder={mandatory ? texts.mandatory : ''}
              onFocus={handleBrandFocused}
            />
            {scoring.isBrandFocused && (
              <>
                <div
                  className={classNames({
                    transparent_back_layer: true,
                  })}
                  role="button"
                  tabIndex={0}
                  onClick={handleBrandUnfocused}
                  onKeyPress={handleBrandUnfocused}
                />
                <div className={classNames({ ns_brand_suggestions: true })}>
                  <FilteredBrandSuggestions />
                </div>
              </>
            )}
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    texts: state.texts.values,
    scoring: state.scoring,
    status: state.status,
    device: state.device,
  }
}

export default connect(mapStateToProps, actionCreators)(ScoringBrandSelector)
