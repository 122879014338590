import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'

const classNames = require('classnames')

export class ScoringDataListProp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      textValue: false,
    }
  }

  componentDidMount = async () => {
    const { scoring, environment, slug } = this.props
    const DataListValueProp = scoring.scoringProps.filter(x => x.slug === slug)
    const textValue = DataListValueProp.length > 0 ? DataListValueProp[0].value.body[environment.lang] : ''

    this.setState({ initialized: true, textValue })
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { textValue } = this.state
    return nextState.textValue !== textValue
  }

  render() {
    const { environment, texts, label, mandatory, values, slug, type, onChangeFunction } = this.props

    const { initialized, textValue } = this.state
    return (
      <>
        {initialized && (
          <>
            <div className={classNames({ ns_prop_label: true })}>{label[environment.lang]}</div>
            <div
              className={classNames({
                ns_prop: true,
                ns_datalist_prop: true,
              })}
            >
              <input
                type="text"
                value={textValue}
                placeholder={mandatory ? texts.mandatory : '--'}
                className={`va_datalist ns_datalist_input ns_datalist_input_${slug}`}
                list={`datalist_${slug}`}
                onChange={e => {
                  const inputValue = e.target.value
                  this.setState({ textValue: inputValue })
                }}
                onBlur={async e => {
                  const inputValue = e.target.value

                  let dataValue = null
                  for (let i = 0; i !== values.length; i += 1) {
                    const val = values[i]
                    const { body } = val
                    const bodyVal = body[environment.defaultLang].toString().toLowerCase()
                    if (bodyVal === inputValue.toLowerCase()) {
                      dataValue = val
                      break
                    }
                  }
                  /*
                  const dataValue = values.find(
                    x =>
                      x.body[environment.defaultLang]
                        .toString()
                        .toLowerCase() === inputValue.toLowerCase()
                  )
                  */

                  let value = null
                  if (dataValue) {
                    value = dataValue
                  } else {
                    const body = {}
                    body[environment.defaultLang] = inputValue

                    value = {
                      body,
                      slug: null,
                      default: false,
                    }
                  }

                  await onChangeFunction(slug, type, label, value)
                }}
              />
              <datalist id={`datalist_${slug}`}>
                {values.map(opt => {
                  return (
                    <option key={opt.slug} id={opt.slug}>
                      {opt.body[environment.defaultLang]}
                    </option>
                  )
                })}
              </datalist>
            </div>
          </>
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    texts: state.texts.values,
    device: state.device,
    scoring: state.scoring,
    status: state.status,
  }
}

export default connect(mapStateToProps, actionCreators)(ScoringDataListProp)
