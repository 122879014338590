import React from 'react'
import { connect } from 'react-redux'

import './styles.scss'

import Burger from '../burger'
import BackIcon from '../../assets/svg/arrow-left.svg'
import HomeIcon from '../../assets/svg/home-icon.svg'
import PlusIcon from '../../assets/svg/plus.svg'
import EmptyComparisonIcon from '../../assets/svg/comparison.svg'
import ComparisonWithNumberIcon from '../../assets/svg/comparison-with-number.svg'
import SearchIcon from '../../assets/svg/search.svg'
import OnlineIcon from '../../assets/svg/online-icon.svg'
import OfflineIcon from '../../assets/svg/offline-icon.svg'
import DotsIcon from '../../assets/svg/dots.svg'

import * as actionCreators from '../../store/actions'
import screenTypesMap from '../../layout/_parts/screenTypesMap'
import AccountPopover from './accountPopover'

const classNames = require('classnames')

export class Header extends React.Component {
  componentDidMount = async () => {
    const { setStickyHeaderStatus } = this.props

    function handleScroll() {
      const headerComponent = document.querySelector('.header_component')
      const isStickyHeader = window.scrollY > 0
      const hasStickyAttr = headerComponent.classList.contains('sticky_header_component')
      if ((isStickyHeader && !hasStickyAttr) || (!isStickyHeader && hasStickyAttr)) {
        setStickyHeaderStatus(isStickyHeader)
      }
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll)
    }
  }

  render() {
    const {
      cobranding,
      authentication,
      device,
      createScoringRecordInStorageAndShowScoringPanel,
      updateScrollDisabledStatus,
      updateBurgerMenuVisible,
      comparedProducts,
    } = this.props

    const essentialModeEnabled = process.env.essentialModeEnabled ? JSON.parse(process.env.essentialModeEnabled) : false

    const renderLogoSection =
      device.screenType === screenTypesMap.desk ||
      device.screenType === screenTypesMap.iPadH ||
      device.screenType === screenTypesMap.iPadV

    const renderUserSection =
      device.screenType === screenTypesMap.desk ||
      device.screenType === screenTypesMap.iPadH ||
      device.screenType === screenTypesMap.iPadV

    const renderPlusSection = device.isOnline && device.screenType !== screenTypesMap.iPhoneSE

    const renderComparisonSection =
      (device.isOnline && JSON.parse(process.env.comparisonBoardEnabled)) || essentialModeEnabled

    function handleNewScoringClicked() {
      updateScrollDisabledStatus(true)
      createScoringRecordInStorageAndShowScoringPanel()
    }

    function handleComparisonBoardClicked() {
      if (!essentialModeEnabled) {
        window.location.href = '/comparison'
      } else {
        window.location.href = '/previewEssentialComparison'
      }
    }

    function handleHomeButtonClicked() {
      if (typeof window !== 'undefined') {
        window.location.href = '/'
      }
    }

    function handleSearchButtonClicked() {
      if (typeof window !== 'undefined') {
        window.location.href = '/products'
      }
    }

    function handleBackButtonClicked() {
      if (typeof window !== 'undefined') {
        // window.history.back()
        window.history.go(-1)
      }
    }

    function handleOpenCloseBurgerMenu() {
      updateScrollDisabledStatus(!device.burgerMenuVisible)
      updateBurgerMenuVisible(!device.burgerMenuVisible)
    }

    return (
      <>
        <header
          className={classNames({
            header_component: true,
            sticky_header_component: device.isStickyHeader,
            isSafari: device.isSafari,
            isFirefox: device.isFirefox,
            desk: device.screenType === screenTypesMap.desk,
            ipadh: device.screenType === screenTypesMap.iPadH,
            ipadv: device.screenType === screenTypesMap.iPadV,
            iphonexr: device.screenType === screenTypesMap.iPhoneXR,
            iphonese: device.screenType === screenTypesMap.iPhoneSE,
          })}
        >
          <div className="header_row">
            <div className="header_left_col">
              <button
                type="button"
                onClick={handleBackButtonClicked}
                className={classNames({
                  styleless_btn: true,
                  header_btn: true,
                  theme_svg: true,
                  theme_filter: true,
                })}
              >
                <BackIcon />
              </button>
              <button
                type="button"
                onClick={handleHomeButtonClicked}
                className={classNames({
                  styleless_btn: true,
                  header_btn: true,
                  theme_svg: true,
                  theme_filter: true,
                })}
              >
                <HomeIcon />
              </button>
              {renderPlusSection && authentication.user.parsedPermissions.canCreateProduct && (
                <button
                  type="button"
                  onClick={handleNewScoringClicked}
                  className={classNames({
                    styleless_btn: true,
                    header_btn: true,
                    theme_svg: true,
                    theme_filter: true,
                  })}
                >
                  <PlusIcon />
                </button>
              )}
              {renderComparisonSection && comparedProducts && (
                <button
                  type="button"
                  onClick={handleComparisonBoardClicked}
                  className={classNames({
                    styleless_btn: true,
                    header_btn: true,
                    last_btn: true,
                    theme_svg: true,
                    theme_filter: true,
                  })}
                >
                  {comparedProducts.length === 0 && <EmptyComparisonIcon />}
                  {comparedProducts.length > 0 && (
                    <div>
                      <ComparisonWithNumberIcon />
                      <div
                        className={classNames({
                          header_btn_badge: true,
                          dozens: comparedProducts.length > 9 && comparedProducts.length < 100,
                          hundreds: comparedProducts.length > 99,
                          theme_background_color: true,
                        })}
                      >
                        {comparedProducts.length}
                      </div>
                    </div>
                  )}
                </button>
              )}
            </div>

            {renderLogoSection && (
              <div
                className={classNames({
                  header_logo_container: true,
                  isSafari: device.isSafari,
                  isFirefox: device.isFirefox,
                  desk: device.screenType === screenTypesMap.desk,
                  ipadh: device.screenType === screenTypesMap.iPadH,
                  ipadv: device.screenType === screenTypesMap.iPadV,
                  iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                  iphonese: device.screenType === screenTypesMap.iPhoneSE,
                })}
                role="button"
                tabIndex={0}
                onClick={handleHomeButtonClicked}
                onKeyPress={handleHomeButtonClicked}
              >
                {process.env.cobrandingMode && (
                  <div
                    className={classNames({
                      header_cobranding_logo_container: true,
                    })}
                  >
                    <img src={`${cobranding.small_client_logo_url}`} alt="client Logo" />
                  </div>
                )}
                <img className="header_valueable_logo" src={`${cobranding.medium_valueable_logo}`} alt="ValueAble" />
              </div>
            )}

            <div className="header_right_col">
              <div
                className={classNames({
                  header_online_indicator: true,
                  theme_circle: true,
                })}
              >
                {device.isOnline && <OnlineIcon />}
                {!device.isOnline && <OfflineIcon />}
              </div>
              <button
                type="button"
                onClick={handleSearchButtonClicked}
                className={classNames({
                  styleless_btn: true,
                  header_btn: true,
                  theme_svg: true,
                  theme_filter: true,
                })}
              >
                <SearchIcon />
              </button>

              {renderUserSection && <AccountPopover />}
              <button
                type="button"
                onClick={handleOpenCloseBurgerMenu}
                className={classNames({
                  styleless_btn: true,
                  header_btn: true,
                  last_btn: true,
                  header_btn_burger: true,
                  header_btn_burger_active: device.burgerMenuVisible,
                  theme_svg: !device.burgerMenuVisible,
                  theme_background_color: device.burgerMenuVisible,
                })}
              >
                <DotsIcon />
              </button>
            </div>
          </div>

          {device.burgerMenuVisible && (
            <div
              className={classNames({
                transparent_back_layer: true,
              })}
              role="button"
              tabIndex={0}
              onClick={handleOpenCloseBurgerMenu}
              onKeyPress={handleOpenCloseBurgerMenu}
            />
          )}
        </header>
        <Burger />
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    cobranding: state.environment.cobranding,
    device: state.device,
    comparedProducts: state.comparisonBoard.comparedProducts,
  }
}

export default connect(mapStateToProps, actionCreators)(Header)
