import storage from '../../storage'
import storageMap from '../../storage/storageMap'
import textsResponse from '../../../src/texts/texts.json'

export async function getTextsFromServer() {
  /*  We will make here the call to the api when texts will be moved on server side
      For now, we have texts stored as local json and we simulate textsResponse
      reading the json directly from his local path
  */
  // const textsResponse = await httpGet('texts')

  if (textsResponse) {
    textsResponse.texts.forEach(async texts => {
      await storage.update(storageMap.texts, texts)
    })
  }
}

export async function loadTextsFromLocalStorage(lang) {
  const response = await storage.getTexts(lang)
  const texts = response.length > 0 ? response[0] : {}
  return texts
}
