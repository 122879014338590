const storageMap = {
  access_token: 'access_token',
  app_version: 'app_version',
  category: 'category',
  cobranding: 'cobranding',
  comment: 'comment',
  comparison_board: 'comparison_board',
  config: 'config',
  downloaded: 'downloaded',
  extra_data: 'extra_data',
  home: 'home',
  locales: 'locale',
  log: 'log',
  media: 'media',
  media_big: 'media_big',
  offline_scorings_list: 'offline_scorings_list',
  reset_requests: 'reset_requests',
  scope: 'scope',
  scoring: 'scoring',
  status: 'status',
  system_data: 'system_data',
  sync_data: 'sync_data',
  sync_queue: 'sync_queue',
  sync_queue_v2: 'sync_queue_v2',
  sync_stack: 'sync_stack',
  template: 'template',
  texts: 'texts',
  user: 'user',
  publishing_services: 'publishing_services',
  tus_urls: 'tus_urls',
}

export default storageMap
