import _ from 'lodash'
import storage from '../storage'
import storageMap from '../storage/storageMap'
import ActionHandlerFactory from './actionHandlers/actionHandlerFactory'
import SyncWorker from './sync.worker.js'

class SyncQueue {
  add = async (action, data) => {
    const handler = ActionHandlerFactory.make(action)
    return handler.add(data)
  }

  remove = async id => {
    return storage.remove(storageMap.sync_queue_v2, id)
  }

  removeMany = async ids => {
    return storage.removeByFilter(storageMap.sync_queue_v2, item => _.includes(ids, item.id))
  }

  removeAll = async () => {
    return storage.empty(storageMap.sync_queue_v2)
  }

  all = async () => {
    const values = await storage.getAll(storageMap.sync_queue_v2)
    return _.sortBy(values, 'added_at') // First in first out
  }

  process = async id => {
    const queueItem = await storage.get(storageMap.sync_queue_v2, id)

    if (!queueItem) {
      throw new Error(`Record ${queueItem.id} does not exists in the sync queue.`)
    }

    const { action } = queueItem

    const syncWorker = new SyncWorker()

    await syncWorker.process(action, queueItem, global.env)
    await storage.remove(storageMap.sync_queue_v2, id)
  }
}

const syncQueue = new SyncQueue()

export default syncQueue
