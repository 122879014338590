import { loadOfflineListFromLocalStorage } from '../../../../va-corejs-v3/actions/offline'
import { generateOfflineScoringObj } from '../../../../va-corejs-v3/utils'
import storage from '../../../../va-corejs-v3/storage'
import storageMap from '../../../../va-corejs-v3/storage/storageMap'
import { createScorePanel } from '../../../../va-corejs-v3/specializations'

export function updateOfflineProductsList() {
  return async dispatch => {
    const list = await loadOfflineListFromLocalStorage()
    dispatch({
      type: 'UPDATE_OFFLINE_LIST',
      list,
    })
  }
}

export function updateOfflineScoringList(scoring) {
  return async dispatch => {
    scoring.props = scoring.scoringProps
    scoring.id = scoring.newScoringId
    const template = await storage.get(storageMap.template, scoring.selectedTemplate)
    const offlineScoring = generateOfflineScoringObj(scoring, template, [scoring.coverPicture])
    const scorePanel = await createScorePanel(
      scoring,
      {
        ...scoring.nodes,
      },
      template.node_definitions
    )
    offlineScoring._source.score_panel = scorePanel
    await storage.update(storageMap.offline_scorings_list, offlineScoring)

    const idbScoring = await storage.get(storageMap.scoring, scoring.newScoringId)
    idbScoring.score_panel = scorePanel
    await storage.update(storageMap.scoring, idbScoring)

    dispatch({
      type: 'UPDATE_OFFLINE_LIST',
      list: [],
    })
  }
}
