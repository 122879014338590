import Dexie from 'dexie'

export const initDb = () => {
  const db = new Dexie(global.env.storageName)

  db.version(1).stores({
    access_token: 'id',
    app_version: 'id',
    category: 'id',
    cobranding: 'id',
    comment:
      'id, [scoring_id+items_father_id], [scoring_id+node_definition_id], scoring_id, node_definition_id, items_father_id',
    config: 'id',
    criteria_template: 'id',
    downloaded: 'id',
    home: 'id',
    locale: 'id',
    log: 'id',
    media:
      'id, [scoring_id+collection], [scoring_id+node_definition_id], [scoring_id+items_father_id], scoring_id, node_definition_id, items_father_id, collection',
    media_big: 'id, scoring_id, node_definition_id, items_father_id, collection',
    node_definition: 'id',
    node_instance: 'id',
    offline_scorings_list: 'id',
    prop: 'id, scoring_id',
    reset_requests: 'id',
    scoring: 'id',
    scope: 'id',
    status: 'id',
    sync_data: 'id',
    sync_queue: 'id, elementType, nodeId, scoringId',
    system_data: 'id',
    template: 'id',
    texts: 'id, language',
    user: 'id',
  })

  db.version(2).stores({
    comparison_board: 'id',
  })

  db.version(3).stores({
    sync_queue: 'id, elementType, nodeId, scoringId, obj',
  })

  db.version(4).stores({
    extra_data: 'id, data',
  })

  db.version(5).stores({
    publishing_services: 'code',
  })

  db.version(6).stores({
    sync_queue_v2: 'id, added_at, action, data',
  })

  db.version(7).stores({
    tus_urls: 'key, upload',
  })

  return db
}
