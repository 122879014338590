import _ from 'lodash'
import {
  saveMediaIntoLocalStorage,
  getItemsFatherMediaFromLocalStorage,
  getScoringTreeMediaFromLocalStorage,
  deleteMediaFromLocalStorage,
  updateMediaInLocalStorage,
} from '../../../../va-corejs-v3/actions/media'
import storage from '../../../../va-corejs-v3/storage'
import storageMap from '../../../../va-corejs-v3/storage/storageMap'
import { decreaseToZero } from '../../../../va-corejs-v3/utils'
import msgBoxMessagesMap from '../../../../va-corejs-v3/utils/msgBoxMessagesMap'
import nodeDefinitionTypeMap from '../../../components/scoring_tree/helper/nodeDefinitionTypeMap'
import mediaCollectionsMap from '../../../../va-corejs-v3/utils/mediaCollectionsMap'
import syncQueue from '../../../../va-corejs-v3/sync/syncQueue'
import { SYNC_ACTION_REORDER_MEDIA } from '../../../../va-corejs-v3/sync/consts'

const arrayMove = require('array-move')

export function updateMediaFile(scoringId, files, mediaType, nodeDefId, collection, itemsFatherId, mediaIdToReplace) {
  return async dispatch => {
    // Check for valid mime types
    const acceptedMimeTypes = ['image/jpeg', 'image/png', 'video/mp4', 'video/quicktime']
    const filesToAdd = []
    for (let i = 0; i < files.length; i += 1) {
      const file = files[i]
      if (acceptedMimeTypes.includes(file.type) || file.type === '') {
        // Since some browsers don't recognize some mime types, I try to accept also empty values, but this is not a good thing.
        filesToAdd.push(file)
      }
    }
    // End of check

    const results = await saveMediaIntoLocalStorage(
      filesToAdd,
      mediaType,
      nodeDefId,
      collection,
      scoringId,
      itemsFatherId,
      mediaIdToReplace
    )
    let localStorageProbablyIsFull = false
    results.media.map(async data => {
      if (!data) {
        localStorageProbablyIsFull = true
      } else {
        const type = mediaIdToReplace ? 'EDIT_IMAGE' : 'UPDATE_IMAGE'
        // this update media into mediaList in redux
        dispatch({
          type,
          media: data.media,
        })
      }
    })

    // This update media count into scoring
    dispatch({
      type: 'UPDATE_SCORING_INTO_SCORINGTREE',
      scoring: results.scoring,
    })

    if (localStorageProbablyIsFull) {
      dispatch({
        type: 'UPDATE_MSGBOX',
        msgboxMessage: msgBoxMessagesMap.idbSizeExeeded,
      })
    }
  }
}

export function updateMedia(scoringId, itemsFatherId) {
  return async dispatch => {
    const media = await getItemsFatherMediaFromLocalStorage(scoringId, itemsFatherId)
    dispatch({
      type: 'UPDATE_MEDIA',
      media,
    })
  }
}

export function updateScoringTreeMedia(scoringId) {
  return async dispatch => {
    const media = await getScoringTreeMediaFromLocalStorage(scoringId)
    dispatch({
      type: 'UPDATE_MEDIA',
      media,
    })
  }
}

/**
 * @param {Array} subset - The subset of media
 * @param {int} oldIndex - The starting index in the subset of the element to move
 * @param {int} newIndex - The new index the moved element in the subset
 * @param {Array} media - All the media
 */
export function changeMediaSubsetOrder(subset, oldIndex, newIndex, media) {
  return async dispatch => {
    const newSubset = arrayMove(subset, oldIndex, newIndex)

    const promises = []

    for (let i = 0; i < newSubset.length; i += 1) {
      promises.push(
        new Promise((resolve, reject) => {
          newSubset[i].order = i + 1
          updateMediaInLocalStorage(newSubset[i])
            .then(response => {
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      )
    }

    await Promise.all(promises)

    const nodeId = newSubset[0].node_id

    await syncQueue.add(SYNC_ACTION_REORDER_MEDIA, {
      node_id: nodeId,
      media: _.map(newSubset, item => {
        return {
          id: item.id,
          order: item.order,
        }
      }),
    })

    dispatch({
      type: 'UPDATE_MEDIA',
      media,
    })

    return newSubset
  }
}

export function deleteMediaAndUpdateRedux(scoringTree, mediaId, media) {
  return async dispatch => {
    let mediaIdx = -1
    for (let i = 0; i !== media.length; i += 1) {
      const itm = media[i]
      if (itm.id === mediaId) {
        mediaIdx = i
        break
      }
    }
    const nodeDefId = media[mediaIdx].node_definition_id

    // decrase forecast item criterion media count
    if (media[mediaIdx].collection === mediaCollectionsMap.forecast_node_pictures) {
      decreaseToZero(scoringTree.scoring.nodes[nodeDefId].forecast, 'mediaCount')
    } else if (media[mediaIdx].collection === mediaCollectionsMap.node_pictures) {
      decreaseToZero(scoringTree.scoring.nodes[nodeDefId], 'mediaCount')
    }

    const template = await storage.get(storageMap.template, scoringTree.scoring.template_id)
    const tmpNode = template.node_definitions[nodeDefId]

    if (tmpNode.type === nodeDefinitionTypeMap.criterion) {
      // decrase forecast item media count
      decreaseToZero(scoringTree.scoring.nodes[tmpNode.parent_id], 'mediaCount')
    }

    decreaseToZero(scoringTree.scoring, 'mediaCount')

    await storage.update(storageMap.scoring, scoringTree.scoring)
    await deleteMediaFromLocalStorage(mediaId)
    media.splice(mediaIdx, 1)
    dispatch({
      type: 'UPDATE_MEDIA',
      media,
    })
    dispatch({
      type: 'UPDATE_SCORING_INTO_SCORINGTREE',
      scoring: scoringTree.scoring,
    })
  }
}
